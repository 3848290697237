import type { App } from "vue";
import { unref, computed } from "vue";
import type { I18nOptions } from "vue-i18n";
import { createI18n } from "vue-i18n";
import { useLocaleStore } from "@/store";
import { setHtmlPageLang } from "./helper";
import { LocaleType, LangModule } from "@/types/locales";

export let i18n: ReturnType<typeof createI18n>;

async function createI18nOptions(): Promise<I18nOptions> {
  const localeStore = useLocaleStore();
  const locale = localeStore.getLocale;
  const defaultLocal = await import(`./lang/${locale}.ts`);
  const message = defaultLocal.default?.message ?? {};
  const { fallback, availableLocales } = localeStore.localInfo;

  setHtmlPageLang(locale);
  localeStore.setLoadLocalePool(locale);

  return {
    legacy: false,
    locale,
    fallbackLocale: fallback,
    messages: { [locale]: message },
    availableLocales: availableLocales,
    sync: true, //If you don’t want to inherit locale from global scope, you need to set sync of i18n component option to false.
    silentTranslationWarn: true, // true - warning off
    missingWarn: false,
    silentFallbackWarn: true,
  };
}

function setI18nLanguage(locale: LocaleType, isPersist = true) {
  const localeStore = useLocaleStore();

  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    (i18n.global.locale as any).value = locale;
  }
  if (isPersist) {
    localeStore.setLocaleInfo({ locale });
  }
  setHtmlPageLang(locale);
}

export async function setupI18n(app: App) {
  const options = await createI18nOptions();
  i18n = createI18n(options);
  app.use(i18n as any);
}

export function useLocale() {
  const localeStore = useLocaleStore();

  const getAntdLocale = computed((): any => {
    const localeMessage: any = i18n.global?.getLocaleMessage(
      localeStore.getLocale
    );
    return localeMessage?.antdLocale ?? {};
  });

  // Switching the language will change the locale of useI18n
  // And submit to configuration modification
  async function changeLocale(locale: LocaleType, isPersist?: boolean) {
    const globalI18n = i18n.global;
    const currentLocale = unref(globalI18n.locale);
    if (currentLocale === locale) {
      return locale;
    }

    if (localeStore.loadLocalePool.includes(locale)) {
      setI18nLanguage(locale, isPersist);
      return locale;
    }
    const langModule = ((await import(`./lang/${locale}.ts`)) as any)
      .default as LangModule;
    if (!langModule) return;

    const { message } = langModule;

    globalI18n.setLocaleMessage(locale, message);
    localeStore.setLoadLocalePool(locale);

    setI18nLanguage(locale, isPersist);
    return locale;
  }

  return {
    changeLocale,
    getAntdLocale,
  };
}
