// 通用语言枚举
export enum LanguageEnum {
  ZH_CN = "zh-CN",
  EN_US = "en-US",
  JA_JP = "ja-JP",
  KG_CN = "kg-CN",
}

// 本地语言枚举
export enum LocaleEnum {
  ZH_CN = "zh_CN",
  EN = "en",
  JA_JP = "ja_JP",
  KG_CN = "kg_CN",
}

// 根据语言枚举本地语言字段
export enum LanguageToLocaleEnum {
  "zh-CN" = "zh_CN",
  "en-US" = "en",
  "ja-JP" = "ja_JP",
  "kg-CN" = "kg_CN",
}

// bimface的语言枚举
export enum BimfaceLocaleEnum {
  zh_CN = "zh_CN",
  en = "en_GB",
}

// forge的语言枚举
export enum FrogeLocaleEnum {
  zh_CN = "zh-HANS",
  en = "en",
  ja_JP = "ja",
  kg_CN = "zh-HANS",
}
