// import * as Minio from "minio-js";
import SparkMD5 from "spark-md5";
import { Scheduler } from "@/utils/scheduler";
import {
  minioUpload,
  getMinioPresignedUrl,
  checkFileByMd5,
  initMultiPartUpload,
  mergeMultipartUpload,
  abortMultipartUpload,
} from "@/api/upload";
import { FileState, FileStatus } from "@/types/upload";
/**minio分段上传
 * 首次上传
 * initiateMultipartUpload初始化分段上传获取uploadId
 * uploadPart 上传段
 * completeMultipartUpload 合并段
 *
 * 二次上传
 * getObjectMetadata获取上传完成的文件
 * listMultipartUploads获取上传信息，返回最近一次上传的uploadId
 * listParts 根据uploadId获取已上传的分片信息
 * uploadPart 上传未完成的段
 * completeMultipartUpload 合并段
 */
const CHUNKSIZE = 5 * 1024 * 1024; // 5 MB
const Concurrent = 2; //单个视频并发上传数量
let Schedulers = [] as any[];
let FileIds = [] as any[]; //所有文件的上传标识

// minio分片上传文件
export async function minioRequest(
  fileState: FileState,
  file: File,
  key: string
) {
  // 这些状态直接返回
  if (
    [
      FileStatus.success,
      FileStatus.processing,
      FileStatus.calculating,
    ].includes(fileState.status)
  )
    return;

  // 分片数量
  const chunkCount = Math.ceil((file.size ?? 0) / CHUNKSIZE);

  // 上传的文件编号集合
  if (!FileIds.includes(fileState?.no)) {
    FileIds.push(fileState?.no);
  }

  // 获取文件的md5和分片列表
  if (!fileState.md5) {
    fileState.status = FileStatus.calculating;
    const { md5, chunkFileList } = await createChunkFileAndMd5(
      file,
      fileState,
      chunkCount
    );
    fileState.md5 = md5;
    fileState.chunkFileList = chunkFileList;
  }

  // 检查文件的md5
  const { listParts = [], url } = await checkFileByMd5({
    md5: fileState.md5,
  }).catch(() => {
    fileState.status = FileStatus.fail;
  });
  // 已经上传过文件
  if (url) {
    fileState.url = url;
    fileState.percent = 100;
    fileState.status = FileStatus.success;
    return;
  }

  // 获取要上传的分片
  const needUploadFile = await checkChunkFile({
    file,
    listParts,
    chunkCount,
    md5: fileState.md5,
    chunkFileList: fileState.chunkFileList,
  }).catch(() => {
    fileState.status = FileStatus.fail;
  });

  // 计算md5耗时长，防止用户删除，判断文件状态
  if (!FileIds.includes(fileState.no)) return;

  // 要上传的文件总的大小
  const needTotalSize = needUploadFile?.reduce(
    (pre, cur) => pre + cur?.chunkFile?.size,
    0
  );

  // 获取已上传文件的进度
  const percent = Math.floor(((file.size - needTotalSize) / file.size) * 100);
  fileState.status = FileStatus.processing;
  fileState.uploadedSize = file.size - needTotalSize;
  fileState.percent = percent >= 1 ? percent - 1 : 0;

  // 并发上传
  const scheduler = new Scheduler(Concurrent);
  needUploadFile?.forEach((item) => {
    scheduler.add(() => {
      return chunkFileUpload(item, fileState, file.size);
    });
  });
  Schedulers.push({ md5: fileState.md5, scheduler });

  // 并发请求
  scheduler.taskStart().then((res: any) => {
    setTimeout(() => {
      createMergeChunkFile(res, fileState);
    }, 2 * 1000);
  });
}

/**
 * 将文件分片拆分，并计算文件的 md5
 * @param file 整个文件 File
 * @param chunkCount 分片数量
 * @param onProgress progress 函数，回传计算的当前进度
 * @returns
 */
function createChunkFileAndMd5(
  file: File,
  fileState: FileState,
  chunkCount: number
): Promise<any> {
  const spark = new SparkMD5();
  const chunkFileList: Blob[] = [];

  return new Promise((resolve) => {
    const str = `${fileState.no}_${fileState.name}`;
    spark.append(str);
    for (let i = 0; i < chunkCount; i++) {
      const start = i * CHUNKSIZE;
      const end = Math.min(file.size, start + CHUNKSIZE);
      const chunk = file.slice(start, end);
      chunkFileList.push(chunk);
    }

    // 将当前文件的md5进度计算回传
    const md5 = spark.end();
    resolve({ md5, chunkFileList });
  });
}

// 检查分片上传
async function checkChunkFile(values: any) {
  const { md5, chunkFileList, chunkCount, file, listParts = [] } = values;
  // 计算当前选择文件需要的分片数量
  const result = await initMultiPartUpload({
    md5,
    chunkCount,
    originFileName: file.name,
  });
  const urls = result.urls || [];

  const needUploadFile: any[] = [];
  // 存放需要去上传的文件数据
  if (!listParts.length) {
    // 若全都没有上传，一一对应，其中 urls 是所有分片上传的 url 集合
    chunkFileList.forEach((item: File, index: number) => {
      needUploadFile.push({
        uploadUrl: urls[index],
        chunkFile: item,
        partNumber: index + 1,
      });
    });
    return needUploadFile;
  } else {
    // 存在上传的，对比 minio 已上传的 listParts（序号），将已上传的过滤掉，只上传未上传的文件
    chunkFileList.forEach((item: File, index: number) => {
      // listParts 索引是从 1 开始的
      const i = listParts.findIndex((v: number) => index + 1 == v);
      if (i === -1) {
        needUploadFile.push({
          uploadUrl: urls[index],
          chunkFile: item,
          partNumber: index + 1,
        });
      }
    });
    return needUploadFile;
  }
}

// 分片文件上传
function chunkFileUpload(item: any, fileState: FileState, totalSize: number) {
  return new Promise((resolve, reject) => {
    const { uploadUrl, chunkFile } = item;
    minioUpload(uploadUrl, chunkFile)
      .then(function (response: any) {
        const newUploaedSize = fileState.uploadedSize + chunkFile.size;
        const percent = Math.floor((newUploaedSize / totalSize) * 100);
        fileState.uploadedSize = newUploaedSize;
        fileState.percent = percent >= 1 ? percent - 1 : 0;
        resolve(response);
      })
      .catch((err: any) => {
        reject(item);
      });
  });
}

// 合并分片请求
async function createMergeChunkFile(res: any, fileState: FileState) {
  const { failCount = 0 } = res || {};
  if (failCount <= 0 && FileIds.includes(fileState.no)) {
    // 检查已上传的分片
    checkFileByMd5({ md5: fileState.md5 })
      .then((res) => {
        const { listParts = [] } = res;
        if (listParts.length == fileState.chunkFileList?.length) {
          // 上传分片与实际分片相等
          mergeMultipartUpload({ md5: fileState.md5 })
            .then((res) => {
              if (res?.url) {
                fileState.url = res.url;
                fileState.percent = 100;
                fileState.status = FileStatus.success;
              } else {
                fileState.status = FileStatus.fail;
              }
            })
            .catch(() => {
              fileState.status = FileStatus.fail;
            });
        } else {
          fileState.status = FileStatus.fail;
        }
      })
      .catch(() => {
        fileState.status = FileStatus.fail;
      });
  } else {
    fileState.status = FileStatus.fail;
  }
}

// 终止请求
export function minioAbortRequest(
  abortFiles?: string[],
  deleteFiles?: string[]
) {
  // 终止请求
  if (abortFiles?.length) {
    const newArr = [] as any;
    Schedulers.forEach((item: any) => {
      if (abortFiles.includes(item.md5)) {
        item?.scheduler?.clearQueue?.();
      } else {
        newArr.push(item);
      }
    });
    Schedulers = newArr;
  } else if (deleteFiles?.length) {
    // 删除请求
    Schedulers.forEach((item: any) => {
      item?.scheduler?.clearQueue?.();
    });
    deleteFiles?.forEach((value: string) => {
      abortMultipartUpload({ md5: value });
    });
    Schedulers = [];
    FileIds = [];
  }
}
