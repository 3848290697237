import type { App } from "vue";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  Router,
} from "vue-router";
import { getBasicRoutes } from "@/router/routes";
import permissionGuard from "./permissionGuard";
import { createPageLoadingGuard, createProgressGuard } from "./stateGuard";

export let router: Router;

export function setupRouter(app: App) {
  router = createRouter({
    // 创建一个 hash 历史记录。
    history: createWebHistory(),
    // 应该添加到路由的初始路由列表。
    routes: getBasicRoutes() as RouteRecordRaw[],
    // 是否应该禁止尾部斜杠。默认为假
    strict: true,
    scrollBehavior: () => ({ left: 0, top: 0 }),
  });
  app.use(router);
  setupRouterGuard(router);
}

// 路由守护
export function setupRouterGuard(router: Router) {
  permissionGuard(router); // 权限控制模块
  createPageLoadingGuard(router); //页面加载
  createProgressGuard(router); //页面加载进度
}
