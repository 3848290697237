import { useUserStore } from "@/store";
import { useAppStore } from "@/store";
export async function initAppConfigStore() {
  const userStore = useUserStore();
  const appStore = useAppStore();

  userStore.setThemeMode(userStore.getThemeModeBoolean);
  userStore.setTheme(userStore.theme);
  appStore.setSiteConfig();
}
