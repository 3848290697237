import request from "@/utils/request";
import { ContentTypeEnum } from "@/enums/httpEnum";

/**
 * 查询巡检记录列表
 */
export function queryPatrolRecordList(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/list",
    method: "get",
    params: params,
  });
}

/**
 * 获取巡检记录详情列表
 */
export function getPatrolDate(params: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/distance/patrolDate",
    method: "get",
    params: params,
  });
}

/**
 * 获取巡检记录详情列表
 */
export function getPatrolDetailList(params: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/detailList",
    method: "get",
    params: params,
  });
}

//获取巡检明细
export function getPatrolDetail(data?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/patrolRecord/detail",
    method: "post",
    data: data,
  });
}

//获取SDK巡检明细
export function getSDKPatrolDetail(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/patrolRecord/sdk/detail",
    method: "get",
    params: params,
  });
}

/**
 * 获取巡检记录上传文件详情
 */
export function getCheckpoint(params: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/upload/checkpoint",
    method: "get",
    params: params,
  });
}

//编辑巡检记录
export function updatePatrolRecord(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/edit",
    method: "post",
    data: params,
  });
}

//上传视频-编辑巡检记录
export function updatePatrolUpload(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/globalUploadEdit",
    method: "post",
    data: params,
  });
}
//新增巡检记录
export function addPatrolRecord(params?: any): Promise<any> {
  return request({
    url: "/project/app/patrol/addPatrol",
    method: "post",
    data: params,
  });
}
//删除巡检记录
export function delPatrolRecord(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/delete",
    method: "post",
    data: params,
  });
}
//检查手动上传巡检次数
export function checkUploadTimes(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/upload/check",
    method: "get",
    params: params,
  });
}
//获取剩余上传巡检视频的次数
export function getCheckedUpload(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/upload/verify",
    method: "post",
    data: params,
  });
}
//获取已校验的手动上传巡检视频信息
export function getRemainTimes(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/upload/remainTimes",
    method: "get",
    params: params,
  });
}
//删除断点信息
export function deleteCheckPoint(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/upload/checkpoint/delete",
    method: "get",
    params: params,
  });
}
//轨迹修正巡检明细
export function getOffsetDetail(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/offset/detail",
    method: "get",
    params: params,
  });
}

//批量更新轨迹点位
export function updateTrajeRecord(data?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/update/trajeRecord",
    method: "post",
    data: data,
  });
}

// 批量删除轨迹
export function deleteTrajeRecord(data?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/deleteBatch/esTrajeRecord",
    method: "post",
    data: data,
  });
}

//更新巡检记录文件上传模式
export function updateUploadMode(data?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/uploadMode/save",
    method: "post",
    data: data,
  });
}
//查询巡检记录文件上传模式
export function getPatrolMode(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/uploadMode/detail",
    method: "get",
    params: params,
  });
}

//巡检结果链接保存
export function updatePatrolResult(data?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/patrolResult/save",
    method: "post",
    data: data,
  });
}

// 多国巡检记录查询
export function queryPatrolRecordBlackboard(params?: any): Promise<any> {
  return request({
    url: "/project/patrolRecord/blackboard",
    method: "get",
    params: params,
  });
}

// 获取 巡检修正快捷短语列表查询 无分页
export function getPhraseNoPageList(
  params: Record<"type", number>
): Promise<any> {
  return request({
    url: "/project/dict/noPageList",
    method: "get",
    params,
  });
}

// 保存 巡检修正快捷短语
export function savePhrase(data: Recordable): Promise<any> {
  return request({
    url: "/project/dict/save",
    method: "post",
    data: data,
  });
}

// 删除 巡检修正快捷短语
export function removePhrase(data: Recordable): Promise<any> {
  return request({
    url: "/project/dict/remove",
    method: "post",
    data: data,
  });
}

// 重新处理手势识别
export function processGesture(params: Record<"id", string>): Promise<any> {
  return request({
    url: "/project/patrolRecord/reprocessingTrajeMarker",
    method: "get",
    params,
  });
}

// 同层最近缩略图
export function getLatestTrackMap(params: Recordable): Promise<any> {
  return request({
    url: "/project/patrolRecord/latestTrajeImg",
    method: "get",
    params,
  });
}

// 轨迹统计数据
export function trajeStatisticsData(params: Recordable): Promise<any> {
  return request({
    url: "/project/patrolRecord/trajeStatistics",
    method: "get",
    params,
  });
}

// 下载轨迹详情
export function downloadTrajeDetail(data: Recordable): Promise<any> {
  return request(
    {
      url: "/project/patrolRecord/downloadDetail",
      method: "post",
      data: data,
      responseType: "blob",
      headers: {
        "Content-type": ContentTypeEnum.FORM_DATA,
        ignoreCancelToken: true,
      },
    },
    {
      isTransformResponse: false,
    }
  );
}

// 获取算法选项
export function getAlgorithmOptions(): Promise<any> {
  return request(
    {
      url: "https://obs-vsleem.vsais.com/test/template_algo_option.json?auth_key=1720600968-ca17b39fcd3540bc85d3b01a6ac45dc4-0-02bdd8ce456210f9b0a8f4f93107a428",
      method: "get",
    },
    {
      joinPrefix: false,
      withToken: false,
      isTransformResponse: false,
    }
  );
}

// 创建测距
export function addMeasure(data: Recordable): Promise<any> {
  return request({
    url: "/project/measure/add",
    method: "post",
    data: data,
  });
}

// 编辑测距
export function editMeasure(data: Recordable): Promise<any> {
  return request({
    url: "/project/measure/edit",
    method: "post",
    data: data,
  });
}

// 删除测距
export function deleteMeasure(data: Recordable): Promise<any> {
  return request({
    url: "/project/measure/delete",
    method: "post",
    data: data,
  });
}

// 测量距离
export function measuringDistance(data: Recordable): Promise<any> {
  return request(
    {
      url: "https://ai.vsais.com/distance/distance",
      method: "post",
      data: data,
    },
    {
      joinPrefix: false,
      withToken: false,
      isTransformResponse: false,
    }
  );
}

// 测量范围
export function unMeasuringRange(data: Recordable): Promise<any> {
  return request(
    {
      url: "https://ai.vsais.com/distance/range",
      method: "post",
      data: data,
    },
    {
      joinPrefix: false,
      withToken: false,
      isTransformResponse: false,
    }
  );
}

// 选择合适的全景测量
export function measuringSelect(data: Recordable): Promise<any> {
  return request(
    {
      url: "https://ai.vsais.com/distance/select",
      method: "post",
      data: data,
    },
    {
      joinPrefix: false,
      withToken: false,
      isTransformResponse: false,
    }
  );
}
