import { defineStore } from "pinia"; // 定义容器
import { usePermissionStore } from "@/store";
import {
  LocaleType,
  LocaleSetting,
  LocaleSelect,
  ForgeLocaleType,
  BimfaceLocaleType,
} from "@/types/locales";

import {
  LanguageEnum,
  LocaleEnum,
  BimfaceLocaleEnum,
  FrogeLocaleEnum,
} from "@/enums/localeEnum";

interface LocaleState {
  localInfo: LocaleSetting;
  loadLocalePool: LocaleType[];
  localList: LocaleSelect[];
  mktLocale?: LocaleType;
}

export const useLocaleStore = defineStore("app-locale", {
  state: (): LocaleState => {
    return {
      localInfo: {
        locale: undefined,
        fallback: LocaleEnum.EN,
        availableLocales: [LocaleEnum.ZH_CN, LocaleEnum.EN, LocaleEnum.JA_JP],
      },
      loadLocalePool: [],
      mktLocale: undefined,
      localList: [
        {
          text: "简体中文",
          auth: "sys.locale.zh",
          event: LocaleEnum.ZH_CN,
          language: LanguageEnum.ZH_CN,
        },
        {
          text: "English",
          auth: "sys.locale.en",
          event: LocaleEnum.EN,
          language: LanguageEnum.EN_US,
        },
        {
          text: "日本語",
          auth: "sys.locale.ja",
          event: LocaleEnum.JA_JP,
          language: LanguageEnum.JA_JP,
        },
        {
          text: "考古",
          auth: "sys.locale.kg",
          event: LocaleEnum.KG_CN,
          language: LanguageEnum.KG_CN,
        },
      ],
    };
  },
  getters: {
    getLocale(): LocaleType {
      return this.localInfo?.locale || LocaleEnum.EN;
    },
    getBimfaceLocale(): BimfaceLocaleType {
      return this.getLocale == LocaleEnum.ZH_CN ||
        this.getLocale == LocaleEnum.KG_CN
        ? BimfaceLocaleEnum.zh_CN
        : BimfaceLocaleEnum.en;
    },
    getForgeLocale(): ForgeLocaleType {
      return FrogeLocaleEnum[this.getLocale];
    },
    getLanguage(): any {
      const local = this.localList.find(
        (item: LocaleSelect) => item.event == this.getLocale
      );
      return local?.language;
    },
    getSysLocale(): LocaleType {
      // 系统用语言参数
      let lang = navigator.language || (navigator as any)?.userLanguage;
      lang = lang?.substr?.(0, 2)?.toLowerCase?.();
      const item = this.localList?.find((item: any) => {
        if (item.event.includes(lang) || item.language.includes(lang)) {
          return item;
        }
      });
      return item?.event as LocaleType;
    },
  },
  actions: {
    setLocaleInfo(info: Partial<LocaleSetting>) {
      this.localInfo = { ...this.localInfo, ...info };
    },

    setLoadLocalePool(locale: LocaleType) {
      this.loadLocalePool.push(locale);
    },

    setMktLocale(value: LocaleType) {
      this.mktLocale = value;
    },

    getEnabledLocale(value: LocaleType) {
      const { hasPermission } = usePermissionStore();
      const item = this.localList?.find((item: any) => {
        if (hasPermission(item.auth) && item.event == this.getSysLocale) {
          return item;
        }
      });
      if (value === LocaleEnum.KG_CN) {
        return value;
      }
      return item?.event || value;
    },
  },
  //开启数据持久化缓存 默认`sessionStorage`储存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, //存储方式
        paths: ["localInfo"], //存储属性 若不填写全缓存
      },
    ],
  },
});
