/**
 * 存储数据
 */
export const setItem = (key: string, value: unknown) => {
  // 将数组、对象类型的数据转化为 JSON 字符串进行存储
  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(key, value as string);
};

/**
 * 获取数据
 */
export function getItem(key: string): unknown {
  const data = window.localStorage.getItem(key);
  try {
    return data && JSON.parse(data);
  } catch (err) {
    return data;
  }
}

/**
 * 删除数据
 */
export const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
};

/**
 * 删除所有数据
 */
export const removeAllItem = () => {
  window.localStorage.clear();
};
