import { AppRouteRecordRaw } from "@/router/types";
import { RouteLocationRaw } from "vue-router";
import { router } from "../index";
import { useI18n } from "@/hooks/useI18n";

export const error_404 = {
  path: "/404",
  name: "404",
  component: () => import("@/views/404/index.vue"),
  meta: {
    title: "404",
    type: "page",
    orderNo: 1,
    keepAlive: true,
    hideTab: true,
    hideMenu: true,
  },
};

const hasRoute = (url: any) => {
  return router.getRoutes().some((item: any) => item.path === url);
};

export function getBasicRoutes() {
  const { t } = useI18n();
  const defaultRouter = {
    path: "/",
    name: "MainView",
    component: () => import("@/layout/index.vue"),
    redirect: (to: RouteLocationRaw) => {
      if (hasRoute("/project/list")) {
        return "/project/list";
      } else if (hasRoute("/data/category/patrol")) {
        return "/data/category/patrol";
      } else if (hasRoute("/device/list")) {
        return "/device/list";
      } else if (hasRoute("/group/role/list")) {
        return "/group/role/list";
      } else {
        return "/user/account";
      }
    },
    meta: {
      type: "page",
      icon: "HomeFilled",
      orderNo: 1,
      hideMenu: true,
      hideChildrenInMenu: false,
      keepAlive: true,
      hideTab: false,
    },
    children: [error_404],
  };
  const basicRoutes: AppRouteRecordRaw[] = [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/login/index.vue"),
      meta: {
        title: t("route.login"),
        hideMenu: true,
      },
    },
    {
      path: "/login/share",
      name: "sharePage",
      redirect: "/login/share/init",
      meta: {
        title: t("route.joinEnterprise"),
        hideMenu: true,
      },
      component: () => import("@/views/login/share/index.vue"),
      children: [
        {
          path: "/login/share/init",
          name: "sharePageIndex",
          component: () => import("@/views/login/share/init.vue"),
          meta: {
            title: t("route.joinEnterprise"),
            hideMenu: true,
          },
        },
        {
          path: "/login/share/success",
          name: "sharePageSuccess",
          component: () => import("@/views/login/share/success.vue"),
          meta: {
            title: t("route.joinEnterprise"),
            hideMenu: true,
          },
        },
        {
          path: "/login/share/fail",
          name: "sharePageFail",
          component: () => import("@/views/login/share/fail.vue"),
          meta: {
            title: t("route.linkExpired"),
            hideMenu: true,
          },
        },
      ],
    },
    {
      path: "/login/linkShare",
      name: "LinkShare",
      component: () => import("@/views/login/linkShare/index.vue"),
      meta: {
        title: t("route.shareLink"),
        hideMenu: true,
      },
    },
    {
      path: "/mobile",
      name: "mobileAlter",
      component: () => import("@/views/system/mobile/index.vue"),
      meta: {
        hideMenu: true,
      },
    },
    {
      name: "Panorama",
      path: "/scene/panorama",
      component: () => import("@/views/panorama/index.vue"),
      meta: {
        orderNo: 10,
        title: t("scene.title.sceneSpaceSharing"),
        keepAlive: false,
        hideBreadcrumb: false,
        hideMenu: true,
        hideTab: true,
        inheritQuery: false,
        hideChildrenInMenu: true,
        link: false,
      },
    },
    {
      name: "User",
      path: "/user",
      redirect: "/user/account",
      component: () => import("@/layout/index.vue"),
      meta: {
        orderNo: 7,
        title: t("route.personalCenter"),
        keepAlive: false,
        hideBreadcrumb: false,
        hideMenu: true,
        hideTab: false,
        inheritQuery: false,
        hideChildrenInMenu: false,
        icon: "a-zhanghuzhanghao",
        level: 1,
        link: false,
      },
      children: [
        {
          name: "Account",
          path: "/user/account",
          component: () => import("@/views/user/account/index.vue"),
          meta: {
            orderNo: 1,
            title: t("route.account"),
            keepAlive: false,
            hideBreadcrumb: false,
            hideMenu: false,
            hideTab: false,
            inheritQuery: false,
            hideChildrenInMenu: false,
            icon: "",
            level: 1,
            link: false,
          },
        },
        {
          name: "Company",
          path: "/user/company",
          component: () => import("@/views/user/company/index.vue"),
          meta: {
            orderNo: 3,
            title: t("route.myBusiness"),
            keepAlive: false,
            hideBreadcrumb: false,
            hideMenu: false,
            hideTab: false,
            inheritQuery: false,
            hideChildrenInMenu: false,
            level: 1,
            link: false,
          },
        },
      ],
    },
    {
      name: "Notification",
      path: "/notifications",
      redirect: "/notifications/receive",
      component: () => import("@/layout/index.vue"),
      meta: {
        orderNo: 8,
        title: t("route.message"),
        keepAlive: false,
        hideBreadcrumb: false,
        hideMenu: true,
        hideTab: false,
        inheritQuery: false,
        hideChildrenInMenu: false,
        level: 1,
        link: false,
      },
      children: [
        {
          name: "notifications",
          path: "/notifications/receive",
          component: () => import("@/views/message/index.vue"),
          meta: {
            orderNo: 1,
            title: t("route.messageReception"),
            keepAlive: false,
            hideBreadcrumb: false,
            hideMenu: false,
            hideTab: true,
            inheritQuery: false,
            hideChildrenInMenu: false,
            level: 1,
            link: false,
          },
        },
      ],
    },
    defaultRouter,
  ];
  return basicRoutes;
}

/**
 * 用于匹配不在路由表中的url将其重定向到404
 */
export const redirectRoute = {
  path: "/:catchAll(.*)",
  redirect: "/404",
  meta: {
    hideTab: true,
    hideMenu: true,
    title: "404",
  },
  name: "redirectRoute",
} as any;
