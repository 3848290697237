import Bowser from "bowser";
/**
 * 获取用户设备信息
 * @returns  isTablet: 平板; isMobile手机
 */
export function identifyDevice() {
  // 解析用户代理字符串
  const browser = Bowser.getParser(window.navigator.userAgent);
  // 获取平台类型
  const platformType = browser.getPlatformType();
  // 是否为移动端设备
  const isMobile = platformType === "mobile";
  // 是否为平板
  const isTablet = platformType === "tablet";
  return {
    isTablet,
    isMobile,
  };
}
