import { defineStore } from "pinia"; // 定义容器
import { router } from "@/router";
import { Menu } from "@/router/types";
import { changePermission, getDeptListNoPage } from "@/api/sys";
import { useUserStore } from "@/store";
import { CompanyInfo, RoleInfo } from "@/types/user";
import { isArray } from "@/utils/is";
import { intersection, cloneDeep } from "lodash-es";
import { removeItem } from "@/utils/storage";
import { APP_TABLE_LOCAL } from "@/constant/index";

interface PermissionState {
  // 后台菜单列表
  backMenuList: Menu[];
  // 用户的角色列表
  role?: RoleInfo;
  // 用户已选择的企业
  companyInfoList: CompanyInfo[];
  // 项目级路由列表
  projectRouteList: any[];
  // 系统级路由
  systemRouteList: any[];
  // 不同环境的权限列表
  envPermissionList: string[];
  // 系统级权限列表
  permissionList: string[];
}

export const usePermissionStore = defineStore("app-permission", {
  state: (): PermissionState => {
    return {
      role: undefined,
      backMenuList: [],
      projectRouteList: [],
      systemRouteList: [],
      companyInfoList: [],
      envPermissionList: [],
      permissionList: [],
    };
  },
  getters: {
    getBackMenuList(): Menu[] {
      return this.backMenuList;
    },
    getProjectRouteList(): any[] {
      return this.projectRouteList;
    },
    geSystemProjectRouteList(): any[] {
      return this.systemRouteList;
    },
    getPermissionList(): string[] {
      return [...this.envPermissionList, ...this.permissionList];
    },
  },
  actions: {
    // 设置菜单
    setBackMenuList(list: Menu[]) {
      this.backMenuList = list;
    },
    // 设置项目下路由列表
    setProjectRouteList(list: any[]) {
      this.projectRouteList = list;
    },
    setSystemRouteList(list: any[]) {
      this.systemRouteList = list;
    },
    // 设置环境权限标识
    setEnvPermissionList(list: string[]) {
      this.envPermissionList.push(...list);
    },
    // 设置系统级权限标识
    setPermissionList(list: string[]) {
      this.permissionList.push(...list);
    },
    // 设置角色
    setRole(role: RoleInfo) {
      this.role = role;
    },
    /**
     * 是否有按钮权限
     * @param value 权限标识
     * @param projectId 项目级需要传projectId，系统级不需要projectId
     * @returns
     */
    hasPermission(value: string | string[], projectId?: string) {
      const getProjectPermissionMap = (projectId: string): string[] => {
        const { userInfo } = useUserStore();
        return [
          ...this.envPermissionList,
          ...(userInfo.pjPermissions || []),
          ...(userInfo.pjPermissionMap?.[projectId] || []),
        ];
      };
      projectId = projectId ? String(projectId) : projectId;
      const permissionList = projectId
        ? getProjectPermissionMap(projectId)
        : this.getPermissionList;
      if (isArray(value)) {
        return intersection(value, permissionList).length > 0;
      } else {
        return permissionList.includes(value);
      }
    },

    async getCompany() {
      //  transferModal样式、逻辑调整；个人企业可删除
      const list = await getDeptListNoPage();
      const { userInfo } = useUserStore();

      if (userInfo.checkType) {
        // 全选企业
        this.companyInfoList = cloneDeep(list).filter(
          (item) => item.changeFlag
        );
      } else {
        //  已选企业：剔除不在列表中的已选企业；
        const newArr = list.map((_: any) => _.id);
        this.companyInfoList = this.companyInfoList.filter((_: any) =>
          newArr.includes(_.id)
        );
      }

      return list;
    },
    async initCompanyInfo() {
      const userStore = useUserStore();
      const { userInfo } = userStore;
      if (userInfo.checkType) {
        this.companyInfoList = userInfo.companyInfos.filter(
          (item) => item.changeFlag
        );
      } else {
        this.companyInfoList = userInfo.companyInfos;
      }
    },
    async togglePermission(
      options: {
        ids: string;
        checkType?: boolean;
        roleId?: number;
      },
      path = ""
    ) {
      const userStore = useUserStore();
      const { access_token, lastLoginUuid } = await changePermission(options);
      userStore.setToken(access_token);
      userStore.setLastLoginParams({
        lastLoginUuid,
      });
      removeItem(APP_TABLE_LOCAL);

      if (path) {
        location.href = path;
      } else {
        location.reload();
      }
    },
    // 重置权限
    resetPermission() {
      this.role = undefined;
      this.backMenuList = [];
      this.projectRouteList = [];
      // this.companyInfoList = [];
      this.permissionList = [];
    },
  },
  //开启数据持久化缓存 默认`sessionStorage`储存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, //存储方式
        paths: [], //存储属性 若不填写全缓存
      },
    ],
  },
});
