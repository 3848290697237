import request from "@/utils/request";
import { getMenuListResultModel } from "@/router/types";

/**登录获取路由 */
export function getRouters(
  params?: Recordable
): Promise<getMenuListResultModel> {
  return request({
    url: "/system/getRouters",
    method: "get",
    params,
  });
}
export function preCheckApi(params: any): Promise<any> {
  return request({
    url: "/system/register/preCheck",
    method: "post",
    data: params,
  });
}

export function bindWechat(params: any = {}): Promise<any> {
  return request({
    url: "/system/user/bindWx",
    method: "post",
    data: params,
  });
}
export function changeWechat(params: any = {}): Promise<any> {
  return request({
    url: "/system/user/changeWx",
    method: "post",
    data: params,
  });
}
/**微信登录 */
export function wechatInRender(): Promise<any> {
  return request({
    url: "/system/wechat/in/render",
    method: "get",
  });
}

/**
 *
 */
export function queryByUserName(param: any): Promise<any> {
  return request({
    url: "/system/user/queryByUserName",
    method: "get",
    params: param,
  });
}
export function getSassInfo(param?: any): Promise<any> {
  return request({
    url: "/system/saasEnterprise/getByHostName",
    method: "get",
    params: param,
  });
}
