import { defineStore } from "pinia"; // 定义容器

interface UnitState {
  laseCadObj: any;
  cadObj: any;
}
export const useUnitStore = defineStore("app-unit", {
  state: (): UnitState => {
    return {
      laseCadObj: {},
      cadObj: {},
    };
  },
  actions: {
    setLaseCadObj(laseCadObj: any) {
      this.laseCadObj = laseCadObj;
    },
    setCadObj(cadObj: any) {
      this.cadObj = cadObj;
    },
  },
  //开启数据持久化缓存 默认`sessionStorage`储存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, //存储方式
        paths: ["laseCadObj", "cadObj"], //存储属性 若不填写全缓存
      },
    ],
  },
});
