import { defineStore } from "pinia"; // 定义容器

interface ProjectState {
  projectDetail: any;
}
export const useProjectStore = defineStore("app-project", {
  state: (): ProjectState => {
    return {
      projectDetail: {},
    };
  },
  actions: {
    setProjectDetail(projectDetail: any) {
      this.projectDetail = projectDetail;
    },
  },
  //开启数据持久化缓存 默认`sessionStorage`储存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, //存储方式
        paths: ["projectDetail"], //存储属性 若不填写全缓存
      },
    ],
  },
});
