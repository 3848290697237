import type { App } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersist from "pinia-plugin-persist";
const store = createPinia();
store.use(piniaPluginPersist);

export function setupStore(app: App<Element>) {
  app.use(store);
}
export * from "./useAppStore";
export * from "./usePermissionStore";
export * from "./useProjectStore";
export * from "./userLocaleStore";
export * from "./useUnitStore";
export * from "./useUploadStore";
export * from "./useUserStore";
export * from "./useRealseeStore";
// export * from "./modules/report"; //不能被抛出（useI18n还未初始化）
