import { computed, watch, unref } from "vue";
import { useRouter } from "vue-router";
import { useTitle as usePageTitle } from "@vueuse/core";
import { useAppStore, useLocaleStore } from "@/store";
import { useI18n } from "@/hooks/useI18n";
import { getName } from "@/api/menu";

export function useTitle() {
  const { t } = useI18n();
  const { currentRoute } = useRouter();
  const appStore = useAppStore();
  const localeStore = useLocaleStore();
  const pageTitle = usePageTitle();

  let beforeId: string | number;

  watch(
    [() => currentRoute.value.path, () => localeStore.getLocale],
    async () => {
      const route = unref(currentRoute);
      const currentId: any = route.query.projectId || route.query.deviceId;
      let title = "";
      if (currentId) {
        if (beforeId !== currentId) {
          title = await getName({ id: currentId });
          pageTitle.value = title;
        }
      } else {
        if (route?.matched.length) {
          const titleList = route?.matched
            ?.filter((_) => _.meta.title)
            .map((_) => t(_.meta.title as string));

          pageTitle.value = titleList?.join("-");
          title = t(route.matched[0].meta.title as string);
        }
      }
      if (title) {
        beforeId = currentId;
        appStore.setPageTitle(title);
      }
    },
    { immediate: true }
  );
}
