import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eab3cac6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isAntIcon)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.name), { key: 0 }))
    : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        class: _normalizeClass([[_ctx.$attrs.class, _ctx.spin && 'svg-icon-spin'], "svg-icon"]),
        "aria-hidden": "true"
      }, [
        _createElementVNode("use", {
          "xlink:href": `#${_ctx.name}`
        }, null, 8, _hoisted_1)
      ], 2))
}