<template>
  <component :is="name" v-if="isAntIcon"></component>
  <svg
    v-else
    :class="[$attrs.class, spin && 'svg-icon-spin']"
    class="svg-icon"
    aria-hidden="true"
  >
    <use :xlink:href="`#${name}`" />
  </svg>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import * as Icons from "@ant-design/icons-vue";
export default defineComponent({
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    spin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isAntIcon = computed(() => isIconDefinition(props.name));

    function isIconDefinition(name: string) {
      return !!Icons[name as keyof typeof Icons];
    }
    return { isAntIcon };
  },
});
</script>
<style lang="less" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  overflow: hidden;
  vertical-align: -0.15em;
  fill: currentcolor;
}

.svg-icon-spin {
  animation: loadingCircle 1s infinite linear;
}
</style>
