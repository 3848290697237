import type { App } from "vue";

import { setupPermissionDirective } from "./permission";
import { setupDompurifyDirective } from "./dompurify";
import { setupLoadingDirective } from "./loading";

export function setupGlobDirectives(app: App) {
  setupPermissionDirective(app);
  setupDompurifyDirective(app);
  setupLoadingDirective(app);
}
