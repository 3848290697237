//切换语言权限
const sysLang = [
  "sys.locale.zh", //登录-切换语言-中文
  "sys.locale.en", //登录-切换语言-英文
  "sys.locale.ja", //登录-切换语言-日文
  "sys.locale.kg", //登录-切换语言-考古
];
export const langPermissions = {
  zh: [
    // "sys.login.wxLogin", //登录-微信登录
    ...sysLang,
    // "account.detail.wxBind", //个人中心-账户-微信绑定
    "account.detail.phoneBind", //个人中心-账户-手机绑定
    "personnel.list.phone", //组织-人员-列表-手机号
    "personnel.detail.phone", //组织-人员-详情-手机号
    "setting.personnel.phone", //项目-设置-人员-列表-手机号
  ],
  en: [
    // "sys.login.wxLogin", //登录-微信登录
    "sys.locale.zh", //登录-切换语言-中文
    "sys.locale.en", //登录-切换语言-英文
    "sys.locale.ja", //登录-切换语言-日文
    // "account.detail.wxBind", //个人中心-账户-微信绑定
    // "account.detail.phoneBind", //个人中心-账户-手机绑定
    // "personnel.list.phone", //组织-人员-列表-手机号
    // "personnel.detail.phone", //组织-人员-详情-手机号
    // "setting.personnel.phone", //项目-设置-人员-列表-手机号
  ],
  jp: [
    "sys.locale.en", //登录-切换语言-英文
    "sys.locale.ja", //登录-切换语言-日文
  ],
} as any;

// saas用户权限
export const saasPermissions = {
  gloden: [],
  biaodian: [],
} as any;

// 不可访问的权限
export const nonPermissions = {
  vsais: ["sys.layout.logo"],
  bimface: ["sys.bim.bimface"],
  forge: ["sys.bim.forge"],
} as any;

//分享不可访问的权限
export const sharePermissions = {
  globalPage: [],
};
