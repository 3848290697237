// 身份验证，发送验证码的type
export const AUTH_TYPE = "vsais_wechat_auth";
export const VERSION = "3.5";
// 短期身份验证
export const SHORTIME_AUTH = "short_time_auth";

// 表格条件
export const APP_TABLE_LOCAL = "app-table";

// 用户信息
export const APP_USER_LOCAL = "app-user";

// 单元格宽度
export enum CellWidthEnum {
  ACTION_WIDTH = 150, // 操作
  DATETIME_WIDTH = 160, // 时间宽度
  CODE_WIDTH = 200, // 16位编号
  PERSON_WIDTH = 150, // 创建人
  PHONE_WIDTH = 130, // 电话
  STATUS_WIDTH = 200, // 状态
  EMAIL_WIDTH = 200, // 邮箱
  PROJECT_WIDTH = 200, // 项目名称
  COMPANY_WIDTH = 200, // 企业名称
  BUILD_WIDTH = 200, // 建筑名称
  THUMBNAIL = 100, // 缩略图
  FLOOR = 150, // 楼层/建筑
  WORKORDER_TYPE_WIDTH = 120, // 工单模板类型
  COMPANY_PROJECT_WIDTH = 180, // 企业/项目
  PERSON_TIME_WIDTH = 200, // 人员/创建时间
  FRAME_TIME_WIDTH = 150, // 帧数/用时
  ACCEPTANCE_PERSON_TIME_WIDTH = 200, // 验收人/时间
  IMAGE_TYPE_WIDTH = 90, // 影像类型
  POINT_INFO_WIDTH = 90, // 定点影像-点位信息
  REPORT_NAME_WIDTH = 200, // 报告名称
  REPORT_TEMPLATE_WIDTH = 200, // 报告模板名称
  FILE_NAME_WIDTH = 120, // 文件名称
  FILE_LENGTH_WIDTH = 100, // 文件大小
  MAPPING_WIDTH = 120, // 映射目标
  TEMPLATE_NAME_WIDTH = 200, // 模板名称(工单、报告)
  DESCRIPTION_WIDTH = 120, // 描述(模板、工单)
  TEMPLATE_STYLE_WIDTH = 120, // 模板样式
  DEVICE_NAME_WIDTH = 200, // 设备名称
  ROLE_NAME_WIDTH = 200, // 角色名称
  SOURCE_WIDTH = 200, // 来源
}

export const empty = "--";

export const OPTION_MAX_Level = 6;

export const INPUT_MAX_LENGTH = 255;

export const FLOOR_MAX_LENGTH = 200; // 楼层最大层数
