import type { App } from "vue";
import SvgIcon from "@/components/w-svg-icon/svg-icon.vue";
// 组件库全局引入
import Antd from "ant-design-vue";
// 本地iconfont注入，供SvgIcon使用
// import "@/assets/iconfont/iconfont.js";
// ant-design的icon
import * as Icons from "@ant-design/icons-vue";

export function registerGlobComp(app: App) {
  app.component("SvgIcon", SvgIcon);
  // todo... 按需引入
  app.use(Antd);
  //图标组件注册到 Vue
  Object.keys(Icons).forEach((key) => {
    app.component(key, Icons[key as keyof typeof Icons]);
  });
}
