import { ContentTypeEnum } from "@/enums/httpEnum";
import { RequestOptions } from "@/types/axios";
const urlPrefix = process.env.VUE_APP_API_URL_PREFIX;
const platForm = window?.location?.origin;

export const axiosConfig = {
  timeout: 30 * 1000,
  headers: {
    "Content-Type": ContentTypeEnum.JSON,
    PlatForm: platForm,
  },
  // 配置项，下面的选项都可以在独立的接口请求中覆盖
  requestOptions: {
    // 需要对返回数据进行处理
    isTransformResponse: true,
    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    isReturnNativeResponse: false,
    // 默认将prefix 添加到url
    joinPrefix: true,
    // 接口拼接地址
    urlPrefix: urlPrefix,
    //  是否加入时间戳
    joinTime: true,
    // 忽略重复请求
    ignoreCancelToken: true,
    // 是否携带token
    withToken: true,
    // 是否需要提示
    isMessgae: true,
    // 请求重试机制
    retryRequest: {
      isOpenRetry: true,
      count: 5,
      waitTime: 2000,
    },
  } as RequestOptions,
};
