const toString = Object.prototype.toString;

export function is(val: unknown, type: string) {
  return toString.call(val) === `[object ${type}]`;
}

export function isDef<T = unknown>(val?: T): val is T {
  return typeof val !== "undefined";
}

export function isUnDef<T = unknown>(val?: T): val is T {
  return !isDef(val);
}

export function isObject(val: any): val is Record<any, any> {
  return val !== null && is(val, "Object");
}

export function isEmpty<T = unknown>(val: T): val is T {
  if (isArray(val) || isString(val)) {
    return val.length === 0;
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0;
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0;
  }

  if (isNullOrUnDef(val)) {
    return true;
  }

  return false;
}

export function isDate(val: unknown): val is Date {
  return is(val, "Date");
}

export function isNull(val: unknown): val is null {
  return val === null;
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) && isNull(val);
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) || isNull(val);
}

export function isNumber(val: unknown): val is number {
  return is(val, "Number");
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
  return (
    is(val, "Promise") &&
    isObject(val) &&
    isFunction(val.then) &&
    isFunction(val.catch)
  );
}

export function isString(val: unknown): val is string {
  return is(val, "String");
}

export function isFunction(val: unknown): val is Fn {
  return typeof val === "function";
}

export function isBoolean(val: unknown): val is boolean {
  return is(val, "Boolean");
}

export function isRegExp(val: unknown): val is RegExp {
  return is(val, "RegExp");
}

export function isArray(val: any): val is Array<any> {
  return val && Array.isArray(val);
}

export function isWindow(val: any): val is Window {
  return typeof window !== "undefined" && is(val, "Window");
}

export function isElement(val: unknown): val is Element {
  return isObject(val) && !!val.tagName;
}

export function isMap(val: unknown): val is Map<any, any> {
  return is(val, "Map");
}

export function isEqual(obj1: Recordable, obj2: Recordable) {
  // 如果其中没有对象
  if (!isObject(obj1) || !isObject(obj2)) {
    //值类型
    return obj1 === obj2;
  }
  // 如果特意传的就是两个指向同一地址的对象
  if (obj1 === obj2) {
    return true;
  }
  // 两个都是对象或者数组，而且不相等
  // 拿到对象key
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  // 先判断长度就可以过滤一些
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }
  // 以obj1为基准 和 obj2 一次递归比较
  for (const key in obj1) {
    // 递归比较当前每一项
    const res = isEqual(obj1[key], obj2[key]);
    // 如果碰到一个不一样的就返回 false
    if (!res) {
      // 跳出for循环
      return false;
    }
  }
  // 否则全相等
  return true;
}

export const isServer = typeof window === "undefined";

export const isClient = !isServer;

export function isLocalhost(path: string): boolean {
  const reg = /^http(s)?:\/\/localhost:8080\/.*/;
  return reg.test(path);
}

export function isUrl(path: string): boolean {
  const reg = /^http(s)?:\/\/([\w\d-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/;
  return reg.test(path);
}
export function isPhone(path: string): boolean {
  const reg = /^1[3456789]\d{9}$/;
  return reg.test(path);
}

export function isEmail(path: string): boolean {
  const reg =
    /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
  return reg.test(path);
}

export function isName(path: string): boolean {
  const reg = /^([\u4e00-\u9fa5]{2,10})$/;
  return reg.test(path);
}

// base字符串不一定是图片
export function isBase64(str: string) {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

export function isBase64Image(str: string) {
  if (typeof str !== "string") {
    return false;
  }

  // 检查字符串是否以Base64图片的典型前缀开头
  const regex = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/;

  if (!str.match(regex)) {
    return false;
  }

  // 提取Base64编码的部分
  const base64str = str.split(",")[1];

  try {
    // 尝试解码，看是否为有效的Base64格式
    atob(base64str);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * 判断是否为视频链接
 * @param url
 * @returns
 */
export function isVideoLink(url: string) {
  const regex = /\.(mp4|avi|mov|wmv|flv)/i;

  return regex.test(url);
}
