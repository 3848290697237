import request from "@/utils/request";
import { ContentTypeEnum } from "@/enums/httpEnum";
import { base64ToFile, dataURLtoBlob } from "@/utils/base64Conver";
const urlPrefix = process.env.VUE_APP_API_URL_PREFIX;

// 文件上传地址
export const getUploadUrl = `${urlPrefix}/brace/upload`;
//文件转图片上传 包含矢量图地址
export const getUploadByImageUrl = `${urlPrefix}/brace/uploadByImageNew`;

//获取华为oss上传秘钥
export function getHwSecret(params?: any): Promise<any> {
  return request({
    url: "/brace/getHwSecret",
    method: "post",
    data: params,
  });
}

//获取七牛上传token
export function getQiniuToken(params?: any): Promise<any> {
  return request({
    url: "/brace/getQnSecret",
    method: "post",
    data: params,
  });
}

//获取minio上传临时地址
export function getMinioPresignedUrl(params?: any): Promise<any> {
  return request({
    url: "/brace/getPresignedUrl",
    method: "get",
    params: params,
  });
}

//获取hw oss图片信息
export function getHWImageInfo(url?: any): Promise<any> {
  return request(
    {
      url: url,
      method: "get",
    },
    {
      joinPrefix: false,
      withToken: false,
      ignoreCancelToken: false,
      isTransformResponse: false,
    }
  );
}

export function uploadApi(params: {
  name?: string;
  file: File | Blob;
  filename?: string;
}): Promise<any> {
  const formData = new FormData();
  const { name = "file", file, filename } = params;
  formData.append(name, file, filename);

  return request({
    method: "post",
    timeout: 0,
    url: "/brace/upload",
    data: formData,
    headers: {
      "Content-type": ContentTypeEnum.FORM_DATA,
    },
  });
}

// 直传BIM到bimface云
export function bimfaceUpload(
  url: string,
  formData?: any,
  cancelerRequest?: Fn,
  onUploadProgress?: Fn
): Promise<any> {
  return request(
    {
      url: url,
      method: "post",
      timeout: 0,
      data: formData,
      headers: {
        "Content-Type": ContentTypeEnum.FORM_DATA,
      },
      onUploadProgress,
    },
    {
      joinPrefix: false,
      withToken: false,
      ignoreCancelToken: false,
      isTransformResponse: false,
      cancelerRequest,
    }
  );
}

// forge的签名url上传
export function forgeUpload(signedUrl: string, file: any): Promise<any> {
  return request(
    {
      url: signedUrl,
      data: file,
      method: "put",
      timeout: 0,
      headers: {
        "Content-Type": ContentTypeEnum.OCTET_STREAM,
      },
    },
    {
      joinPrefix: false,
      withToken: false,
      ignoreCancelToken: false,
      isTransformResponse: false,
    }
  );
}

// minio的签名url上传
export function minioUpload(
  signedUrl: string,
  file: any,
  cancelerRequest?: Fn,
  onUploadProgress?: Fn
): Promise<any> {
  return request(
    {
      url: signedUrl,
      data: file,
      method: "put",
      timeout: 120 * 1000,
      headers: {
        "Content-Type": ContentTypeEnum.OCTET_STREAM,
      },
      onUploadProgress,
    },
    {
      joinPrefix: false,
      withToken: false,
      ignoreCancelToken: false,
      isTransformResponse: false,
      isMessgae: false,
      retryRequest: { isOpenRetry: false },
      cancelerRequest,
    }
  );
}

// 以物搜物
export function objectSearch(params: {
  file: string;
  projectId: number | string;
}): Promise<any> {
  const formData = new window.FormData();
  const file = base64ToFile(params.file);
  formData.append("file", file);
  formData.append("projectId", String(params.projectId));

  return request({
    method: "post",
    url: "/project/patrolRecord/objectSearch/query",
    data: formData,
    headers: {
      "Content-type": ContentTypeEnum.FORM_DATA,
    },
  });
}

// minio的md5检查
export function checkFileByMd5(params?: any): Promise<any> {
  return request(
    {
      url: `/brace/multipart/check/${params.md5}`,
      method: "get",
      params: params,
    },
    {
      retryRequest: { isOpenRetry: false },
    }
  );
}

// minio初始化多分片上传
export function initMultiPartUpload(params?: any): Promise<any> {
  return request(
    {
      url: `/brace/multipart/init`,
      method: "get",
      params: params,
    },
    {
      retryRequest: { isOpenRetry: false },
    }
  );
}

// minio合并多分片上传
export function mergeMultipartUpload(params?: any): Promise<any> {
  return request(
    {
      url: `/brace/multipart/merge/${params.md5}`,
      method: "get",
      params: params,
    },
    {
      retryRequest: { isOpenRetry: false },
    }
  );
}

// minio删除分片上传
export function abortMultipartUpload(params?: any): Promise<any> {
  return request(
    {
      url: `/brace/multipart/abort/${params.md5}`,
      method: "get",
      params: params,
    },
    {
      retryRequest: { isOpenRetry: false },
    }
  );
}
