// 异步限制器 限制异步任务并发数量

export class Scheduler {
  public queue: (() => Promise<any>)[];
  public maxCount: number;
  public runCounts: number; // 剩余待执行任务数
  public counts: number; // 任务总数
  public successCounts: number; // 执行成功的任务数
  public failCounts: number; // 执行失败的任务数
  constructor(maxCount: number) {
    this.queue = [];
    this.maxCount = maxCount;
    this.runCounts = 0;
    this.counts = 0;
    this.successCounts = 0;
    this.failCounts = 0;
  }
  add(target: () => Promise<any>) {
    this.queue.push(target);
  }
  taskStart() {
    return new Promise((resolve) => {
      this.counts = this.queue.length;
      this.successCounts = 0;
      this.failCounts = 0;
      for (let i = 0; i < this.maxCount; i++) {
        this.request(resolve);
      }
    });
  }
  // promise任务状态全部非pending时，resolve
  request(resolve: (value: unknown) => void) {
    if (this.counts <= this.successCounts + this.failCounts) {
      // 任务执行完毕
      resolve({ successCount: this.successCounts, failCount: this.failCounts });
      return;
    }
    if (!this.queue || !this.queue.length || this.runCounts >= this.maxCount) {
      return;
    }
    this.runCounts++;
    const task = this.queue.shift();
    task?.().then(
      () => {
        this.runCounts--;
        this.successCounts++;
        this.request(resolve);
      },
      () => {
        this.runCounts--;
        this.failCounts++;
        this.request(resolve);
      }
    );
  }
  clearQueue() {
    this.queue = [];
  }
}
