import { defineStore } from "pinia";
import { removeItem } from "@/utils/storage";
import { themeSwitchover } from "@/logics/updateTheme";
import { CodeType, LoginParams } from "@/api/type/user";
import { UserInfo, EnterpriseShare, LastLogin } from "@/types/user";
import { getTokenByAccssUrl, getTokenByAuth } from "@/api/linkShare";
import {
  login,
  getUserInfo,
  sendPhoneCodeApi,
  wechatRender,
  sendEmailCodeApi,
  logout,
} from "@/api/sys";
import { redirectRoute } from "@/router/routes/index";
import { AppRouteRecordRaw } from "@/router/types";
import { getRouters } from "@/api/user";
import { transformObjToRouteList } from "@/router/utils/method";
import { router } from "@/router";
import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { isPhone, isEmail } from "@/utils/is";
import { useAppStore, usePermissionStore } from "@/store";
import { generateMenu, filterRouters } from "@/router/utils/method";
import { ObsTypeEnum } from "@/enums/appEnum";
import {
  APP_USER_LOCAL,
  APP_TABLE_LOCAL,
  SHORTIME_AUTH,
} from "@/constant/index";
import { toRaw } from "vue";

type UserState = {
  collapsed: boolean;
  theme: string;
  themeMode: string;
  userInfo: UserInfo;
  token: string;
  lastUpdateTime: number;
  enterpriseShare?: EnterpriseShare;
  toured?: boolean; //被引导过
  lastLoginParams: LastLogin;
};
// 项目级的路由集合
let cacheProjectRouteMap: Recordable = {};

export const useUserStore = defineStore("app-user", {
  state: (): UserState => {
    return {
      collapsed: false,
      theme: "#5bb36f",
      themeMode: "light",
      lastUpdateTime: 0,
      userInfo: {} as any,
      token: "",
      toured: undefined,
      enterpriseShare: undefined,
      lastLoginParams: {} as LastLogin,
    };
  },
  getters: {
    getThemeModeBoolean(): boolean {
      return Boolean(this.themeMode === "dark");
    },
    hasUserInfo(): boolean {
      return JSON.stringify(this.userInfo) !== "{}";
    },
    geStoretUserInfo(): UserInfo | null {
      return this.userInfo;
    },
    getToken(): string {
      return this.token;
    },
    createByName(): string {
      return this.userInfo.nickName || this.userInfo.userName;
    },
    // 通过分享链接登录的用户
    isShareUser(): boolean {
      return this.userInfo.loginType == 4;
    },
  },
  actions: {
    // 设置组件主题颜色
    setTheme(color: string) {
      themeSwitchover(color);
      this.theme = color;
    },
    // 设置暗黑主题
    setThemeMode(checked: boolean) {
      let themeMode = "";
      if (checked) {
        themeMode = "dark";
      } else {
        themeMode = "light";
      }
      document.querySelector("html")?.setAttribute("data-theme", themeMode);
      this.themeMode = themeMode;
    },
    setToken(token?: any) {
      this.token = token;
    },
    setUserInfo(info: UserInfo) {
      this.userInfo = info;
      this.lastUpdateTime = new Date().getTime();
    },
    setPartialUserInfo(info: Partial<UserInfo>) {
      this.userInfo = Object.assign({}, this.userInfo, info);
    },
    // 设置是否引导过
    setToured(value?: boolean) {
      this.toured = value;
    },
    setLastLoginParams(value: LastLogin) {
      this.lastLoginParams = value;
    },
    // fast用户登陆
    async getTokenByAccssUrl() {
      try {
        const data = await getTokenByAccssUrl({
          accessUrl: window.location.href,
        });
        //type: 0有效，1过期
        const { token, lastLoginUuid } = data;
        this.setLastLoginParams({
          lastLoginUuid,
        });
        // save token
        this.setToken(token);
        return Promise.resolve(true);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getTokenByAuthCode(params: any) {
      const { access_token, lastLoginUuid } = await getTokenByAuth(params);
      this.setLastLoginParams({
        lastLoginUuid,
      });
      access_token && this.setToken(access_token);
    },
    async login(params: LoginParams): Promise<UserInfo | null> {
      try {
        const data = await login(params);
        const { access_token: token, lastLoginUuid = "" } = data;

        // 设置token
        this.setToken(token);
        this.setLastLoginParams({
          lastLoginUuid,
        });
        // 获取用户信息
        await this.initInfo();
        // 初始化动态路由
        await this.initDynamicRoute();
        // 设置引导
        if (this.toured == undefined) {
          this.setToured(false);
        }
        if (this.enterpriseShare?.isShare === "1") {
          // 企业链接邀请人员流程中
          router.push({
            path: `/login/share/init`,
            query: this.enterpriseShare.query,
          });
        } else {
          //跳转到首页
          router.push("/");
        }
        return this.userInfo;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setEnterpriseShare(data: EnterpriseShare) {
      this.enterpriseShare = data;
    },
    async initInfo() {
      // 初始化userInfo
      await this.getUserInfo();

      // 初始化选中的 企业信息
      const { initCompanyInfo } = usePermissionStore();
      initCompanyInfo();
    },
    // 系统级路由和项目级路由只能使用一种
    // 初始化系统级路由
    async initDynamicRoute() {
      this.resetDynamicRoute(); // 重置动态路由

      // 初始化系统级路由
      const routes = await this.getSysDynamicRoute();
      const permissionStore = usePermissionStore();
      permissionStore.setSystemRouteList(routes);
      routes.forEach((route) => {
        try {
          router.addRoute(route as unknown as RouteRecordRaw);
        } catch (err) {
          console.warn(err);
        }
      });
      await this.initMenu();
    },
    // 获取系统级路由
    async getSysDynamicRoute(): Promise<AppRouteRecordRaw[]> {
      const cur = (await getRouters()) as AppRouteRecordRaw[];
      // 处理动态组件导入
      const res = transformObjToRouteList(cur);
      // 最后添加 不匹配路由进入 404
      res.push(redirectRoute);
      return res;
    },
    // 初始化项目级路由
    async initProjectDynamicRoute(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized
    ) {
      // 移除之前的项目级路由
      const permissionStore = usePermissionStore();
      const beforeProjectRoutes = permissionStore.getProjectRouteList || [];
      beforeProjectRoutes.forEach((item) => {
        router.removeRoute(item.name);
      });

      // 添加新的项目级路由
      const routes = await this.getProjectDynamicRoute(to);
      cacheProjectRouteMap[to.query.projectId as string] = routes;
      routes.forEach((route) => {
        try {
          if (router.hasRoute(route.name)) {
            router.removeRoute(route.name);
          }
          router.addRoute(route as unknown as RouteRecordRaw);
        } catch (err) {
          console.warn(err);
        }
      });

      // 暂存项目级别路由对象，在退出项目时需移除
      permissionStore.setProjectRouteList(routes);
      await this.initMenu();
    },
    // 是否需要加载项目级路由
    isInitProjectRoute(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized
    ) {
      const allProjectRouteObjs = this.userInfo.allProjectMenus;

      const toIsProject = allProjectRouteObjs.some((item: { path: string }) => {
        return item.path === (to.redirectedFrom || to).path;
      });
      const fromIsProject =
        !from ||
        allProjectRouteObjs.some((item: AppRouteRecordRaw) => {
          return item.path === from.path;
        });
      // 从非项目级路由到项目级路由中去，此时to的路由应该为redirectRoute重定向后的error_404了
      if (!fromIsProject && toIsProject) {
        const permissionStore = usePermissionStore();
        if (
          !cacheProjectRouteMap[to.query.projectId as string] ||
          toRaw(permissionStore.getProjectRouteList) !==
            cacheProjectRouteMap[
              (to.query.projectId as string) ||
                (to.redirectedFrom?.query.projectId as string)
            ]
        ) {
          return true;
        }
        return false;
      }
      // 项目间切换
      if (
        fromIsProject &&
        toIsProject &&
        from.query.projectId !== to.query.projectId
      ) {
        return true;
      }
      return false;
    },
    // 获取项目级路由
    async getProjectDynamicRoute(
      to: RouteLocationNormalized
    ): Promise<AppRouteRecordRaw[]> {
      to = to.redirectedFrom || to;
      const cur = (await getRouters({
        projectId: to.query.projectId,
      })) as AppRouteRecordRaw[];
      // 处理动态组件导入
      const res = transformObjToRouteList(cur);
      // 最后添加 不匹配路由进入 404
      res.push(redirectRoute);
      return res;
    },
    async initMenu() {
      const routes = filterRouters(router.getRoutes() as any);

      const res = generateMenu(routes);
      const usePermission = usePermissionStore();
      usePermission.setBackMenuList(res as any);
      return res;
    },
    async getUserInfo() {
      const res = await getUserInfo();
      const {
        hiddenButton = 0,
        hiddenMenu = 0,
        hiddenTittle = 0,
        permissions = [],
        minioUrlPrefix,
        obsType,
        pullType,
        role,
      } = res;
      const appStore = useAppStore();
      const permissionStore = usePermissionStore();
      permissionStore.setPermissionList(permissions); // 系统级权限标识
      permissionStore.setRole(role);
      appStore.setAppConfig({
        showHeader: !Number(hiddenTittle),
        showTopMenu: !Number(hiddenButton),
        showMenu: !Number(hiddenMenu),
        sourceType: pullType,
        obsType: obsType || ObsTypeEnum.hw,
        minioUrlPrefix,
      });
      this.userInfo = { ...res, realName: res.userName };
    },

    async loginOut() {
      try {
        await logout();
        const permissionStore = usePermissionStore();

        this.setToken(undefined); // 移除token
        this.setUserInfo({} as UserInfo); // 移除个人信息
        permissionStore.resetPermission(); // 移除权限按钮
        this.resetDynamicRoute(); // 重置动态路由
        removeItem(APP_USER_LOCAL);
        removeItem(APP_TABLE_LOCAL);
        window.sessionStorage.removeItem(SHORTIME_AUTH); // 清除身份认证
        router.replace("/login");
      } catch (error) {
        console.error(error);
      }
    },
    // 重置动态路由
    resetDynamicRoute() {
      const permissionStore = usePermissionStore();
      // 移除之前的系统级路由
      const beforeSysRoutes = permissionStore.geSystemProjectRouteList || [];
      beforeSysRoutes.forEach((route) => {
        if (router.hasRoute(route.name)) {
          router.removeRoute(route.name);
        }
      });
      // 移除之前的项目级路由
      cacheProjectRouteMap = {};
      const beforeProjectRoutes = permissionStore.getProjectRouteList || [];
      beforeProjectRoutes.forEach((route) => {
        if (router.hasRoute(route.name)) {
          router.removeRoute(route.name);
        }
      });
    },
    /**
     * @description: get wechat url
     */
    async wechatRender() {
      try {
        return await wechatRender();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    /**
     * @description: send code
     */
    async sendCodeAction(params: {
      userName: string;
      type: CodeType;
    }): Promise<any | null> {
      try {
        const { userName } = params;
        if (isPhone(userName)) {
          return await sendPhoneCodeApi({
            ...params,
            phones: userName,
          });
        } else if (isEmail(userName)) {
          return await sendEmailCodeApi({
            ...params,
            to: userName,
          });
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },

  //开启数据持久化缓存 默认`sessionStorage`储存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, //存储方式
        paths: [
          "language",
          "theme",
          "themeMode",
          "token",
          "collapsed",
          "toured",
          "lastLoginParams",
        ], //存储属性 若不填写全缓存
      },
    ],
  },
});
