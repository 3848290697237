import { LocaleType } from "@/types/locales";

export function setHtmlPageLang(locale: LocaleType) {
  document.querySelector("html")?.setAttribute("lang", locale);
}

export function genMessage(langs: any, prefix = "lang") {
  const modules: Recordable = {};

  langs?.keys()?.forEach((path: string) => {
    const langFileModule = langs?.(path)?.default || {};
    const fileName = path.replace(`./${prefix}/`, "").replace(/^\.\//, "");
    const lastIndex = fileName.lastIndexOf(".");
    const moduleName = fileName.substring(0, lastIndex);
    if (moduleName) {
      modules[moduleName] = langFileModule;
    }
  });

  return modules;
}
