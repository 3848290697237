import type { Router } from "vue-router";
import nProgress from "nprogress";

nProgress.configure({ showSpinner: false });

export function createPageLoadingGuard(router: Router) {
  // do nothing.
}

export function createProgressGuard(router: Router) {
  router.beforeEach(async (to) => {
    if (to.meta.loaded) {
      return true;
    }
    nProgress.start();
    return true;
  });

  router.afterEach(async () => {
    nProgress.done();
    return true;
  });
}
