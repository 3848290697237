import { defineStore } from "pinia"; // 定义容器
import { langPermissions, nonPermissions } from "@/router/permissions";
import { AppConfig } from "@/types/app";
import { getSassInfo } from "@/api/user";
import { getURLArg } from "@/utils/url";
import { usePermissionStore, useLocaleStore } from "@/store";
import { useLocale } from "@/locales";
import { HostTypeEnum } from "@/enums/httpEnum";
import { LanguageEnum, LanguageToLocaleEnum } from "@/enums/localeEnum";
import {
  CountryEnum,
  SourceTypeEnum,
  BimTypeEnum,
  ObsTypeEnum,
} from "@/enums/appEnum";

interface AppState {
  pageTitle?: string;
  appConfig?: AppConfig;
}

export const useAppStore = defineStore("app", {
  state: (): AppState => ({
    pageTitle: "",
    appConfig: {
      showMenu: true,
      showHeader: true,
      showTopMenu: true,
      logoUrl: "",
      shortLogoUrl: "",
      appDownloadUrl: "",
      iosDownloadUrl: "",
      obsType: "",
      minioUrlPrefix: "",
      enterpriseName: "",
      acceptLanguage: "",
    },
  }),
  getters: {
    isGlodon(): boolean {
      return this.appConfig?.sourceType === SourceTypeEnum.GLODON;
    },
    isZhuBao(): boolean {
      return this.appConfig?.sourceType === SourceTypeEnum.ZHUBAO;
    },
    isXZD(): boolean {
      return this.appConfig?.sourceType === SourceTypeEnum.XZD;
    },
  },
  actions: {
    // 设置标题
    setPageTitle(value: any) {
      this.pageTitle = value;
    },
    // 设置系统配置
    setAppConfig(values: AppConfig) {
      this.appConfig = { ...this.appConfig, ...values };
    },
    //设置站点配置
    async setSiteConfig() {
      const permissionStore = usePermissionStore();
      const hostName = window.location.hostname;

      // 根据特殊域名处理不可访问权限
      if (hostName != HostTypeEnum.VSAIS) {
        permissionStore.setEnvPermissionList(nonPermissions.vsais);
      }

      const result = await getSassInfo({ hostName });
      const {
        appDownloadUrl,
        iosDownloadUrl,
        ticketShareUrl,
        enterpriseLogo: logoUrl,
        smallEnterpriseLogo: shortLogoUrl,
        serveEnv: country = CountryEnum.zh,
        bimType = BimTypeEnum.BIMFACE,
        acceptLanguage: language = LanguageEnum.ZH_CN,
        acceptLanguage,
        enterpriseName,
        videoObsType: obsType = ObsTypeEnum.hw,
      } = result;
      this.setAppConfig({
        logoUrl,
        shortLogoUrl,
        appDownloadUrl,
        iosDownloadUrl,
        ticketShareUrl,
        bimType,
        country,
        language,
        enterpriseName,
        acceptLanguage,
        obsType,
      });

      const permissions = [];
      // 根据不同国家服务控制可访问的权限
      if (country) {
        permissions.push(...langPermissions[country]);
      }

      // 根据bimtype判断使用哪个bim组件
      if (bimType == BimTypeEnum.FORGE) {
        // forge viewer
        permissions.push(...nonPermissions.forge);
      } else {
        // bimface
        permissions.push(...nonPermissions.bimface);
      }

      // 设置权限标识
      permissionStore.setEnvPermissionList(permissions);

      const localeStore = useLocaleStore();
      // url mkt参数
      const mkt = getURLArg(location.href, "mkt");
      if (mkt) {
        const item = localeStore.localList.find(
          (_: any) => _.event === mkt || _.language === mkt
        );
        item && localeStore.setLocaleInfo({ locale: item.event });
      }
      // 设置系统语言
      if (!localeStore.localInfo?.locale) {
        const { changeLocale } = useLocale();
        const env =
          LanguageToLocaleEnum[language as keyof typeof LanguageToLocaleEnum];
        const local = localeStore.getEnabledLocale(env);
        changeLocale(local);
      }
    },
  },
  //开启数据持久化缓存 默认`sessionStorage`储存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, //存储方式
        paths: ["appConfig"], //存储属性 若不填写全缓存
      },
    ],
  },
});
