import { FileState, FileStatus, UploadMode } from "@/types/upload";
import { getHwSecret } from "@/api/upload";
import ObsClient from "esdk-obs-browserjs/src/obs";
import { Scheduler } from "@/utils/scheduler";
/**华为云分段上传
 * 首次上传
 * initiateMultipartUpload初始化分段上传获取uploadId
 * uploadPart 上传段
 * completeMultipartUpload 合并段
 *
 * 二次上传
 * getObjectMetadata获取上传完成的文件
 * listMultipartUploads获取上传信息，返回最近一次上传的uploadId
 * listParts 根据uploadId获取已上传的分片信息
 * uploadPart 上传未完成的段
 * completeMultipartUpload 合并段
 */
const PartSize = 5 * 1024 * 1024; // 10 MB
const Concurrent = 2; //单个视频并发上传数量
let Schedulers = [] as any;

export async function hwRequest(
  fileState: FileState,
  file: File,
  key: string,
  mode: UploadMode
) {
  const secret = await getHwSecret();
  fileState.url = `${secret.domain}${key}`;
  //创建OBS对象
  const hwClient = new ObsClient({
    access_key_id: secret.ak,
    secret_access_key: secret.sk,
    server: secret.endPoint,
    timeout: 3000,
  });
  const params = {
    Bucket: secret.bucketName,
    Key: key,
    Prefix: key,
  };
  const isCompleted = await checkIsCompleted(hwClient, params);
  if (isCompleted) {
    //已全部上传
    fileState.percent = 100;
    fileState.status = FileStatus.success;
  } else {
    //检查是否部分上传
    const uploadId = await getHwCheckpoint(hwClient, params);
    if (!uploadId) {
      //首次上传
      const uploadId = await initMultiPartUpload(hwClient, params);
      const completeParts = [] as any;
      uploadPart(fileState, file, uploadId, completeParts, hwClient, params);
    } else {
      //二次上传续传
      const completeParts = await listAllUploadParts(
        uploadId,
        hwClient,
        params
      );
      uploadPart(fileState, file, uploadId, completeParts, hwClient, params);
    }
  }
}

//检测文件是否全部完成
function checkIsCompleted(hwClient: any, params: any): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      await hwClient.listObjects(params, (err: any, result: any) => {
        if (err) {
          reject(err);
        } else {
          if (
            result.CommonMsg.Status < 300 &&
            result.InterfaceResult.Contents.length
          ) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });
    } catch (err: any) {
      reject(err);
    }
  });
}
//检测文件是否部分上传，并返回最近一次上传的uploadId
async function getHwCheckpoint(hwClient: any, params: any) {
  return new Promise((resolve, reject) => {
    hwClient.listMultipartUploads(params, (err: any, result: any) => {
      if (err) {
        reject(err);
      } else if (
        result.CommonMsg.Status < 300 &&
        result.InterfaceResult &&
        result.InterfaceResult.Uploads.length
      ) {
        const uploads = result.InterfaceResult.Uploads;
        resolve(uploads[uploads.length - 1].UploadId);
      } else {
        resolve("");
      }
    });
  });
}
//根据uploadId获取已上传分片信息
async function listAllUploadParts(
  uploadId: any,
  hwClient: any,
  params: any
): Promise<any> {
  let completeParts = [] as any;
  const listAll = async function (partNumberMarker = null) {
    return new Promise((resolve, reject) => {
      hwClient.listParts(
        {
          ...params,
          UploadId: uploadId,
          PartNumberMarker: partNumberMarker,
        },
        (err: any, result: any) => {
          if (err) {
            reject(err);
          } else {
            if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
              const parts = result.InterfaceResult.Parts.map((_: any) => {
                return {
                  PartNumber: parseInt(_.PartNumber),
                  ETag: _.ETag,
                };
              });
              completeParts = [...completeParts, ...parts];
              if (result.InterfaceResult.IsTruncated === "true") {
                resolve(listAll(result.InterfaceResult.NextPartNumberMarker));
              } else {
                resolve(completeParts);
              }
            } else {
              reject(new Error("Failed to list parts"));
            }
          }
        }
      );
    });
  };
  await listAll();
  return completeParts;
}
//分段上传-初始化
function initMultiPartUpload(hwClient: any, params: any) {
  return new Promise((resolve, reject) => {
    hwClient.initiateMultipartUpload(params, (err: any, result: any) => {
      if (err) {
        reject(err);
      } else if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
        const uploadId = result.InterfaceResult.UploadId;
        resolve(uploadId);
      }
    });
  });
}

//分片上传，parts不为空时实现续传功能
async function uploadPart(
  fileState: FileState,
  file: File,
  uploadId: any,
  parts: any,
  hwClient: any,
  params: any
) {
  const completeParts = [...parts];
  const partNumbers = parts?.map((_: any) => _.PartNumber) || [];
  const count = Math.ceil(file.size / PartSize);
  fileState.status = FileStatus.processing;
  if (partNumbers.length) {
    fileState.percent = parseInt((completeParts.length * 100) / count);
  }
  let startTime = null as any;
  const scheduler = new Scheduler(Concurrent);
  for (let n = 1; n <= count; n++) {
    if (!partNumbers.includes(n)) {
      if (!startTime) {
        startTime = new Date();
      }
      scheduler.add(() => {
        const promise = uploadNextPart(n, hwClient, uploadId, file, params)
          .then((data: any) => {
            completeParts.push({
              PartNumber: n,
              ETag: data,
            });
            fileState.percent = parseInt((completeParts.length * 100) / count);
          })
          .catch((err: any) => {
            fileState.status = FileStatus.fail;
            throw err;
          });
        return promise;
      });
    }
  }
  Schedulers.push(scheduler);
  scheduler
    .taskStart()
    .then(async () => {
      if (count === completeParts.length) {
        completeMultiUpload(
          uploadId,
          completeParts,
          fileState,
          hwClient,
          params
        );
      }
    })
    .catch(() => {
      fileState.status = FileStatus.fail;
    });
}
//单段上传，成功上传时返回ETag值
function uploadNextPart(
  n: number,
  hwClient: any,
  uploadId: any,
  file: any,
  params: any
): Promise<any> {
  const count = Math.ceil(file.size / PartSize);
  const lastPartSize = file.size % PartSize;
  return new Promise((resolve, reject) => {
    hwClient.uploadPart(
      {
        ...params,
        PartNumber: n,
        UploadId: uploadId,
        SourceFile: file,
        PartSize: count === n ? lastPartSize : PartSize,
        Offset: (n - 1) * PartSize,
      },
      (err: any, result: any) => {
        if (err) {
          reject(err);
        } else {
          if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
            resolve(result.InterfaceResult.ETag);
          }
        }
      }
    );
  });
}
//校验分片是否全部上传成功
function completeMultiUpload(
  uploadId: any,
  parts: any,
  fileState: any,
  hwClient: any,
  params: any
) {
  const newParts = parts.sort((a: any, b: any) => a.PartNumber - b.PartNumber);
  hwClient.completeMultipartUpload(
    {
      ...params,
      UploadId: uploadId,
      Parts: newParts,
    },
    function (err: any, result: any) {
      if (err) {
        fileState.status = FileStatus.fail;
      } else {
        if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
          fileState.status = FileStatus.success;
          fileState.percent = 100;
        }
      }
    }
  );
}
// 终止请求
export function hwAbortRequest() {
  Schedulers.forEach((item: any) => {
    item.clearQueue();
  });
  Schedulers = [];
}
