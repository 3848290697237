import axios from "axios";
import { cloneDeep } from "lodash-es";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { isFunction } from "@/utils/is";
import { RequestOptions, Result } from "@/types/axios";
import { useUserStore } from "@/store";
import { AxiosCanceler } from "./http/axiosCancel";
import { axiosConfig } from "./http/axiosConfig";
import {
  requestInterceptors,
  requestInterceptorsCatch,
  responseInterceptors,
  responseInterceptorsCatch,
  beforeRequestHook,
  transformResponseHook,
} from "./http/axiosTransform";

const service = axios.create(axiosConfig);

const axiosCanceler = new AxiosCanceler();

// 请求拦截器配置处理
service.interceptors.request.use((config: any) => {
  const { ignoreCancelToken }: any = config?.requestOptions || {};
  const ignoreCancel = ignoreCancelToken;
  !ignoreCancel && axiosCanceler.addPending(config);
  if (requestInterceptors && isFunction(requestInterceptors)) {
    config = requestInterceptors(config);
  }
  return config;
});

// 请求拦截器错误捕获
service.interceptors.request.use(undefined, requestInterceptorsCatch);

//响应结果拦截器处理
service.interceptors.response.use((res: AxiosResponse<any>) => {
  res && axiosCanceler.removePending(res.config);
  if (responseInterceptors && isFunction(responseInterceptors)) {
    res = responseInterceptors(res);
  }
  return res;
});

// 响应结果拦截器错误捕获
service.interceptors.response.use(undefined, (error) => {
  return responseInterceptorsCatch(service as any, error);
});

export default function request<T = any>(
  config: AxiosRequestConfig,
  options?: RequestOptions
): Promise<T> {
  let conf: Recordable = cloneDeep(config);
  conf.axiosCanceler = axiosCanceler;

  const { requestOptions } = axiosConfig;

  const opt: RequestOptions = Object.assign({}, requestOptions, options);
  if (beforeRequestHook && isFunction(beforeRequestHook)) {
    conf = beforeRequestHook(conf, opt);
  }
  conf.requestOptions = opt;

  return new Promise((resolve, reject) => {
    service
      .request<any, AxiosResponse<Result>>(conf)
      .then((res: AxiosResponse<Result>) => {
        if (transformResponseHook && isFunction(transformResponseHook)) {
          try {
            const ret = transformResponseHook(res, opt);
            resolve(ret);
          } catch (err) {
            reject(err || new Error("request error!"));
          }
          return;
        }
        resolve(res as unknown as Promise<T>);
      })
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          // rewrite error message from axios in here
        }
        reject(e);
      });
  });
}
