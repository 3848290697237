<template>
  <a-config-provider :locale="getAntdLocale">
    <!-- vue3.0配置 -->
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.path === '/bind'" />
      </keep-alive>
      <component :is="Component" v-if="$route.path !== '/bind'" />
    </router-view>
  </a-config-provider>
</template>

<script lang="ts" setup>
import { useLocale } from "@/locales";
import { useTitle } from "@/hooks/useTitle";
import { initAppConfigStore } from "@/logics/initAppConfig";
import "dayjs/locale/zh-cn";
import "dayjs/locale/ja";

const { getAntdLocale } = useLocale();
// 初始化内部系统配置
initAppConfigStore();
// 设置页面标题
useTitle();
</script>
