export enum PageEnum {
  // basic login path
  BASE_LOGIN = "/login",
  // basic home path
  BASE_HOME = "/",
  // 404 page path
  PAGE_NOT_FOUND = "/404",
  // share page path
  SHARE_PAGE = "/login/share/init",
  // share success page path
  SHARE_SUCCESS_PAGE = "/login/share/success",
  // share fail page path
  SHARE_FAIL_PAGE = "/login/share/fail",
  //share link page path
  SHARE_LINK_PAGE = "/login/linkShare",
  //手机限制访问页面地址
  MOBILE_LIMT_PAGE = "/mobile",
  // 简单的全景
  PANORAMA = "/scene/panorama",
}
