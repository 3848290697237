import { RouteItem } from "@/router/types";
import { AppRouteRecordRaw, Menu } from "@/router/types";
import { cloneDeep } from "lodash-es";
import { isUrl } from "@/utils/is";

// 过滤按钮
export function filterMenuData(menuData: RouteItem[]) {
  return menuData.filter((item) => {
    if (item.meta?.type === "button") {
      return false;
    }
    if (item.children) {
      item.children = item.children.map((child) => filterMenuData([child])[0]);
    }
    return true;
  });
}

// 路径处理
function joinParentPath(menus: Menu[], parentPath = "") {
  for (let index = 0; index < menus.length; index++) {
    const menu = menus[index];
    if (!(menu.path.startsWith("/") || isUrl(menu.path))) {
      menu.path = `${parentPath}/${menu.path}`;
    }
    if (menu?.children?.length) {
      joinParentPath(
        menu.children,
        menu.meta?.hidePathForChildren ? parentPath : menu.path
      );
    }
  }
}

const LAYOUT = () => import("@/layout/index.vue");
// 将json对象变成路由对象
export function transformObjToRouteList<T = AppRouteRecordRaw>(
  routeList: AppRouteRecordRaw[]
): T[] {
  routeList.forEach((route) => {
    transformObjToRoute(route);
  });
  return routeList as unknown as T[];

  function transformObjToRoute(route: AppRouteRecordRaw) {
    const component = route.component as string;
    if (component) {
      if (component.toUpperCase() === "LAYOUT") {
        route.component = LAYOUT;
      } else {
        asyncImportRoute(route);
      }
    }
    if (route.children && route.children.length) {
      transformObjToRouteList(route.children);
    }
    return route;
  }
  // 处理component动态引入
  function asyncImportRoute(route: AppRouteRecordRaw) {
    if (!route) return;
    const { component } = route;
    const startFlag = component.startsWith("/");
    const endFlag = component.endsWith(".vue") || component.endsWith(".tsx");
    const startIndex = startFlag ? 1 : 0;
    const lastIndex = endFlag ? component.lastIndexOf(".") : component.length;
    //保留/和.vue中间
    const comp = component.substring(startIndex, lastIndex);
    route.component = () => import("@/views/" + comp + ".vue");
  }
}

/**
 *
 * @param routes router.getRoutes()
 * @returns 菜单路由表
 */
export function filterRouters(routes: AppRouteRecordRaw[]) {
  const allChildrenRouteMap = _getRouteChildrenMap(routes);
  let res = [] as AppRouteRecordRaw[];
  // 去除getRoutes中打平的路由对象
  routes.forEach((route) => {
    if (!allChildrenRouteMap[route.path]) {
      res.push(route);
    }
  });
  // 去除需要隐藏的、无meta的路由
  res = res.filter((route) => {
    return !route.meta || !route.meta.hideMenu;
  });

  return res;

  function _getRouteChildrenMap(routes: AppRouteRecordRaw[]) {
    const map = {} as Record<string, boolean>;
    routes.forEach((route) => {
      _generateMap(route, map);
    });

    function _generateMap(
      route: AppRouteRecordRaw,
      map: Record<string, boolean>
    ) {
      if (route.children && route.children.length) {
        route.children.forEach((cRoute) => {
          _generateMap(cRoute as any, map);
          map[cRoute.path] = true;
        });
      }
    }

    return map;
  }
}

// 将路由转换成菜单
export function generateMenu(routeModList: AppRouteRecordRaw[]) {
  // 借助 lodash 深拷贝
  const cloneRouteModList = cloneDeep(routeModList);
  // 提取树指定结构
  const list = treeMap(cloneRouteModList, {
    conversion: (node: AppRouteRecordRaw) => {
      const { meta: { title, hideMenu = false } = {} } = node;

      return {
        ...(node.meta || {}),
        meta: node.meta,
        compName: node.name,
        name: title,
        hideMenu,
        path: node.path,
        ...(node.redirect ? { redirect: node.redirect } : {}),
      };
    },
  });

  return list;

  /**
   * @description: Extract tree specified structure
   * @description: 提取树指定结构
   */
  function treeMapEach(
    data: any,
    { children = "children", conversion }: { children?: string; conversion: Fn }
  ) {
    const haveChildren =
      Array.isArray(data[children]) && data[children].length > 0;
    const conversionData = conversion(data) || {};
    if (haveChildren) {
      return {
        ...conversionData,
        [children]: data[children].map((i: number) =>
          treeMapEach(i, {
            children,
            conversion,
          })
        ),
      };
    } else {
      return {
        ...conversionData,
      };
    }
  }

  function treeMap<T = any>(
    treeData: T[],
    opt: { children?: string; conversion: Fn }
  ): T[] {
    return treeData.map((item) => treeMapEach(item, opt));
  }
}
