import { ConfigProvider } from "ant-design-vue";
/**
 * @description 传入颜色进行修改ant主题色
 * @param primaryColor ant主题颜色
 */
export function themeSwitchover(primaryColor: string) {
  ConfigProvider.config({
    theme: {
      primaryColor,
    },
  });
}

/**
 * @description 传入值进行修改css变量值
 * @param prop 要修改的css变量名
 * @param val 要修改的值
 */
export function setCssVar(
  prop: string,
  val: any,
  dom = document.documentElement
) {
  dom.style.setProperty(prop, val);
}
