import request from "@/utils/request";
import {
  LoginResultModel,
  RegisterParams,
  LoginParams,
  CodeResultModel,
  CodeType,
} from "@/api/type/user";
import { UserInfo, CompanyInfo } from "@/types/user";

/**
 * 注册
 */
export function registerApi(params: RegisterParams) {
  return request({
    url: "/system/register",
    method: "put",
    data: params,
  });
}

/**
 * 登录
 */
export function login(params: LoginParams): Promise<LoginResultModel> {
  return request({
    url: "/system/login",
    method: "POST",
    data: params,
  });
}
/**登出 */
export function logout() {
  return request({
    url: "/system/logout",
    method: "post",
  });
}

/**
 * 获取用户信息
 */
export function getUserInfo(): Promise<any> {
  return request({
    url: "/system/getInfo",
    method: "get",
  });
}

/**
 * 获取短信验证码-手机
 */
export function sendPhoneCodeApi(params: {
  phones: string;
  type: CodeType;
  userName: string;
  version?: "3.5";
  domain?: string;
}): Promise<CodeResultModel> {
  const _data = Object.assign(
    {
      domain: window.location.hostname,
      version: "3.5",
    },
    params
  );
  return request({
    url: "/brace/api/v1/sms/send",
    method: "post",
    data: _data,
  });
}

/**
 * 获取验证码-邮箱
 */
export function sendEmailCodeApi(params: {
  domain?: string;
  to: string;
  type: CodeType;
  userName: string;
}): Promise<CodeResultModel> {
  const _data = Object.assign(
    {
      domain: window.location.hostname,
      version: "3.5",
    },
    params
  );
  return request({
    url: "/brace/api/v1/email/sendWithTemplate",
    method: "post",
    data: _data,
  });
}

/**微信登录 */
export function wechatRender(): Promise<any> {
  return request({
    url: "/system/wechat/render",
    method: "get",
  });
}

/**修改用户信息 */
export function editUserInfo(data: Partial<UserInfo>) {
  return request({
    url: "/system/user/edit",
    method: "post",
    data,
  });
}

/** 验证身份 */
export function valid(data: any) {
  return request({
    url: "/brace/api/code/valid",
    method: "post",
    data,
  });
}

/** 修改手机号 */
export function resetPhone(data: any) {
  return request({
    url: "/system/user/resetPhone",
    method: "post",
    data,
  });
}

/** 修改邮箱号 */
export function resetEmail(data: any) {
  return request({
    url: "/system/user/resetEmail",
    method: "post",
    data,
  });
}

/** 注销账号 */
export function removeUser(data: any) {
  return request({
    url: "/system/user/remove",
    method: "post",
    data,
  });
}

/** 修改密码 */
export function resetPassword(data: any) {
  return request({
    url: "/system/user/resetPassword",
    method: "post",
    data,
  });
}
/** 忘记密码 */
export function forgetPassword(data: any) {
  return request({
    url: "/system/user/forgetPassword",
    method: "post",
    data,
  });
}

/** 解绑微信 */
export function unbindWx() {
  return request({
    url: "/system/user/unbindWx",
    method: "post",
  });
}

/** 查询部门列表 */
export function getDeptList(): Promise<{
  rows: CompanyInfo[];
  total: number;
}> {
  return request({
    url: "/system/company/queryByUser",
    method: "get",
    params: { pageNum: 1, pageSize: 1000 },
  });
}
/** 查询部门列表不分页 */
export function getDeptListNoPage(): Promise<CompanyInfo[]> {
  return request({
    url: "/system/company/queryAllByUser",
    method: "get",
  });
}

/** 切换权限 */
export function changePermission(data: {
  ids: string;
  checkType?: boolean; // 多选 全选
  roleId?: number; // 单选企业需要角色id
}): Promise<any> {
  return request({
    url: "/system/changePerNew",
    method: "post",
    data,
  });
}

/** 创建企业 */
export function addCompany(data: any): Promise<any> {
  if (
    data.location &&
    Object.prototype.toString.call(data.location) === "[object Array]"
  ) {
    data.location = data.location.join(",");
  }
  return request({
    url: "/system/company/add",
    method: "post",
    data: data,
  });
}

/** 查询企业信息 */
export function getCompanyInfo(): Promise<any> {
  return request({
    url: "/system/company/queryListByUser",
    method: "get",
    params: { pageNum: 1, pageSize: 1000 },
  }).then((res: any) => {
    res &&
      res.rows.forEach((item: any) => {
        if (item.location && typeof item.location === "string") {
          item.location = item.location ? item.location.split(",") : [];
        }
      });
    return res;
  });
}

export function getCompanyDetail(params: { id: number }): Promise<any> {
  return request({
    url: "/system/company/detail",
    method: "get",
    params,
  });
}
