export function getURLArg(url: string, name: string) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  const r = url.substr(url.indexOf("?") + 1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

// 使用JavaScript获取URL中的文件名，并去除问号后面的部分
export function getFileNameFromUrl(url: string) {
  try {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;

    // 检查是否有斜杠
    if (!pathname || pathname.length === 0) {
      throw new Error("URL pathname is empty");
    }

    // 通过最后一个斜杠分割路径
    const parts = pathname.split("/");

    // 获取最后一个部分，通常是文件名
    const fileName = parts.pop();

    // 检查是否存在文件名
    if (!fileName || fileName.length === 0) {
      throw new Error("There is no file name in the URL pathname");
    }

    return fileName;
  } catch (error: any) {
    console.error("Error extracting file name:", error?.message + ":" + url);
    return url;
  }
}
