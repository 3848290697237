import { type Directive, type App } from "vue";

export function setupLoadingDirective(app: App) {
  const directive: Directive = {
    mounted(el, binding) {
      if (binding.value) {
        // 创建一个加载中的DOM元素
        const loadingElement = document.createElement("div");
        loadingElement.className = "custom-directive-loading";
        loadingElement.innerHTML =
          '<div class="custom-directive-loading-spinner"></div>';
        el.loadingElement = loadingElement;
        el.appendChild(loadingElement);
      }
    },
    updated(el, binding) {
      if (binding.value) {
        if (!el.loadingElement) {
          // 如果还没有加载元素，则创建并添加
          const loadingElement = document.createElement("div");
          loadingElement.className = "custom-directive-loading";
          loadingElement.innerHTML =
            '<div class="custom-directive-loading-spinner"></div>';
          el.loadingElement = loadingElement;
          el.appendChild(loadingElement);
        }
      } else {
        if (el.loadingElement) {
          // 移除加载元素
          el.removeChild(el.loadingElement);
          el.loadingElement = null;
        }
      }
    },
  };
  app.directive("loading", directive);
}
