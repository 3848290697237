// 国家枚举
export enum CountryEnum {
  zh = "zh",
  en = "en",
  jp = "jp",
}

// saas服务枚举
export enum SourceTypeEnum {
  GLODON = "glodon", //广联达
  BIAODIAN = "biaodian", //标点
  ZHUBAO = "zhubao", //筑保
  XZD = "D6C", //新中大
}

// bim服务枚举
export enum BimTypeEnum {
  BIMFACE = "bimface",
  FORGE = "forge",
}

// 上传服务枚举
export enum ObsTypeEnum {
  hw = "huawei",
  aws = "amazon",
  minio = "minio",
}
