import "ant-design-vue/dist/antd.variable.min.css";
import "nprogress/nprogress.css";
import "tailwindcss/tailwind.css";
import "@/design/index.less";
import App from "./App.vue";
import { createApp } from "vue";
import { setupRouter } from "@/router";
import { registerGlobComp } from "@/components/registerGlobComp";
import { setupStore } from "@/store";
import { setupGlobDirectives } from "@/directives";
import { setupI18n } from "@/locales";
const app = createApp(App);

// 配置 store
setupStore(app);

// 注册全局组件
registerGlobComp(app);

// 多语言配置
await setupI18n(app);

// 配置路由
setupRouter(app);

// 注册全局指令
setupGlobDirectives(app);

// 实例挂载
app.mount("#app");

// if (process.env.NODE_ENV === "development") {
//   app.config.warnHandler = (err, instance, info) => {
//     // 用于为 Vue 的运行时警告指定一个自定义处理函数。
//     console.error("err:", err);
//     console.error("instance:", instance);
//     console.error("info:", info);
//   };
// }
