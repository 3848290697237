import request from "@/utils/request";

/**菜单列表 */
export function getMenuList(): Promise<any> {
  return request({
    url: "/system/menu/list",
    method: "get",
  });
}
/**获取无按钮菜单列表 */
export function getMenuWithOUtBtn(): Promise<any> {
  return request({
    url: "/system/menu/noButtonList",
    method: "get",
  });
}
/**新增菜单 */
export function addMenu(params: any): Promise<any> {
  return request({
    url: "/system/menu/add",
    method: "post",
    data: params,
  });
}
/**修改菜单 */
export function modifyMenu(params: any): Promise<any> {
  return request({
    url: "/system/menu/edit",
    method: "post",
    data: params,
  });
}
/**删除菜单 */
export function deleteMenu(params: any): Promise<any> {
  return request({
    url: "/system/menu/remove",
    method: "post",
    data: params,
  });
}

/**获取路由跳转name，项目传id 设备传uuid */
export function getName(params: { id: string }): Promise<any> {
  return request({
    url: "/project/project/getProjectOrDeviceName",
    method: "get",
    params: params,
  });
}
