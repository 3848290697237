import { type Directive, type App, type DirectiveBinding, unref } from "vue";
import { usePermissionStore } from "@/store";
import { router } from "@/router/index";

/**
 *  按钮权限指令
 * 项目级权限使用方式1： v-auth:projectId="BtnPermission | BtnPermission[]"
 * 项目级权限使用方式2： v-auth.project="BtnPermission | BtnPermission[]" 此时会从路由query中提取projectId
 * 系统级权限使用方式1： v-auth="BtnPermission | BtnPermission[]"
 * 如果使用了v-if的指令，此时可以使用修饰符hidden
 * @param el
 * @param binding
 * @returns
 */
export function setupPermissionDirective(app: App) {
  const authDirective: Directive = {
    mounted,
  };
  app.directive("auth", authDirective);
}

function mounted(el: Element, binding: DirectiveBinding<any>) {
  isAuth(el, binding);
}

function isAuth(el: Element, binding: any) {
  const permissionStore = usePermissionStore();
  const { value, modifiers, arg } = binding;
  if (!value) return;

  let has: boolean;
  // 区分项目级按钮权限、系统级按钮权限
  if (arg || modifiers.project) {
    // 项目级权限判断
    const projectId = unref(router.currentRoute).query.projectId as string;
    has = permissionStore.hasPermission(value, projectId || arg || "");
  } else {
    // 系统级权限判断
    has = permissionStore.hasPermission(value);
  }

  if (!has) {
    if (modifiers.hidden) {
      (el as HTMLElement).style.display = "none";
    } else {
      el.parentNode?.removeChild(el);
    }
  }
}
