// 权限校验
import { useUserStore } from "@/store/useUserStore";
import type { Router, RouteRecordNormalized } from "vue-router";
import { identifyDevice } from "@/utils/device";
import { PageEnum } from "@/enums/pageEnum";
import { error_404 } from "./routes";
import { omit } from "lodash-es";
const whiteList = [
  PageEnum.BASE_LOGIN,
  PageEnum.SHARE_PAGE,
  PageEnum.SHARE_SUCCESS_PAGE,
  PageEnum.SHARE_FAIL_PAGE,
  PageEnum.SHARE_LINK_PAGE,
  PageEnum.MOBILE_LIMT_PAGE,
  PageEnum.PANORAMA,
];

const phoneWhiteList = [
  PageEnum.BASE_LOGIN,
  PageEnum.SHARE_PAGE,
  PageEnum.SHARE_SUCCESS_PAGE,
  PageEnum.SHARE_FAIL_PAGE,
  PageEnum.MOBILE_LIMT_PAGE,
  PageEnum.PANORAMA,
]; // 手机设备可访问路由白名单
export default function installPremission(router: Router) {
  const userStore = useUserStore();
  const os = identifyDevice();

  router.beforeEach(async (to, from) => {
    const query = { ...to.query };

    // 手机登陆提示
    if (os.isMobile && !phoneWhiteList.includes(to.path as PageEnum)) {
      return PageEnum.MOBILE_LIMT_PAGE;
    }

    // 免密登陆
    if (query.fastlogincode) {
      // 有访问码 跳转访问码验证页面
      if (to.query.accessFlag === "1") {
        return {
          path: `/login/linkShare`,
          query: { accessUrl: window.location.href },
        };
      } else {
        //无访问码
        //登陆换token，更新筛选信息，跳转到指定页面
        await userStore.getTokenByAccssUrl();
        return {
          path: to.path,
          query: omit(to.query, ["accessFlag", "fastlogincode"]),
        };
      }
    }
    //登陆隐藏侧边栏、导航栏
    if (query.authorizationCode) {
      await userStore.getTokenByAuthCode(query);
    }

    if (userStore.token) {
      if (to.path === PageEnum.BASE_LOGIN) {
        return PageEnum.BASE_HOME;
      } else {
        if (!userStore.hasUserInfo) {
          await userStore.initInfo();
          await userStore.initDynamicRoute();
          // 判断是否需要初始化项目级路由
          const isNeedInt = userStore.isInitProjectRoute(to, from);
          if (isNeedInt && (to.redirectedFrom || to).query.projectId) {
            await userStore.initProjectDynamicRoute(to, from);
          }
          return (to.redirectedFrom || to).fullPath;
        } else {
          // 判断是否需要初始化项目级路由
          const isNeedInt = userStore.isInitProjectRoute(to, from);
          if (isNeedInt && (to.redirectedFrom || to).query.projectId) {
            await userStore.initProjectDynamicRoute(to, from);
            return (to.redirectedFrom || to).fullPath;
          } else {
            // 重定向到其他路由，但无该路由时
            if (to.name === error_404.name && to.redirectedFrom) {
              const cRoute = router
                .getRoutes()
                .find((item) => item.path == to.redirectedFrom?.path);
              // 寻找能够渲染的子路由路径
              const effectPath = cRoute && findEffectPath(cRoute);
              if (
                cRoute?.children.length &&
                effectPath &&
                effectPath !== to.path
              ) {
                // 导航到路由的子路由
                return {
                  path: effectPath,
                  query: { ...(to.redirectedFrom.query || {}) },
                };
              }
              return true;
            } else {
              return true;
            }
          }
        }
      }
    } else {
      if (whiteList.includes(to.path as PageEnum)) {
        return true;
      } else {
        return PageEnum.BASE_LOGIN;
      }
    }
  });
}

function findEffectPath(route: RouteRecordNormalized) {
  const stack = [...(route.children || [])] as RouteRecordNormalized[];
  while (stack.length) {
    const cur = stack.shift();
    if (cur && cur.children) {
      stack.unshift(...(cur.children as any));
    } else {
      if ((cur as any)?.component) {
        return (cur as any).path;
      }
    }
  }
}
