import { defineStore } from "pinia"; // 定义容器
import { useUserStore } from "@/store";
interface RealseeState {
  lastCadImageIds: Recordable;
}
export const useRealseeStore = defineStore("app-realsee", {
  state: (): RealseeState => {
    return {
      lastCadImageIds: {},
    };
  },
  actions: {
    getLastCadImageId(floorId: string) {
      const userStore = useUserStore();
      const { userId } = userStore.userInfo;
      return this.lastCadImageIds[`${userId}_${floorId}`];
    },
    setlastCadImageIds(floorId: string, cadId: string) {
      const userStore = useUserStore();
      const { userId } = userStore.userInfo;
      this.lastCadImageIds[`${userId}_${floorId}`] = cadId;
    },
  },
  //开启数据持久化缓存 默认`sessionStorage`储存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, //存储方式
        paths: ["lastCadImageIds"], //存储属性 若不填写全缓存
      },
    ],
  },
});
