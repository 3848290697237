import request from "@/utils/request";

//新增分享
export function addLinkShare(params: any): Promise<any> {
  return request({
    url: "/system/share/add",
    method: "post",
    data: params,
  });
}
//分享列表
export function getLinkShareList(params: any): Promise<any> {
  return request({
    url: "/system/share/list",
    method: "get",
    params: params,
  });
}
//删除分享
export function removeLinkShare(params: any): Promise<any> {
  return request({
    url: "/system/share/remove",
    method: "post",
    data: params,
  });
}
//通过url获取token
export function getTokenByAccssUrl(params: any): Promise<any> {
  return request({
    url: "/system/share/getTokenByAccssUrl",
    method: "get",
    params: params,
  });
}
//通过authorizationCode获取token
export function getTokenByAuth(params: any): Promise<any> {
  return request({
    url: "/system/getTokenByAuthorizationCode",
    method: "get",
    params: params,
  });
}
//修改分享
export function modifyLinkShare(params: any): Promise<any> {
  return request({
    url: "/system/share/edit",
    method: "post",
    data: params,
  });
}
//取消分享
export function cancelLinkShare(params: any): Promise<any> {
  return request({
    url: "/system/share/cancel",
    method: "post",
    data: params,
  });
}

/**
 * 修改链接状态
 */
export function updateLinkShare(data: any): Promise<any> {
  return request({
    url: "/system/share/upShareStatus",
    method: "post",
    data: data,
  });
}
